import Logo from 'components/common/logo';
import { useMaskPasswordFields } from 'utils/analytics';

export function LoginSidePanel() {
  const styles = {
    root: {
      width: '45%',
      position: 'absolute',
      height: '100%',
      backgroundColor: '#ffffff',
      color: '#0f1040',
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      margin: '3.44rem 4.69rem 0',
    },
    logo: {
      width: 171,
      height: 40,
      paddingBottom: '3rem',
    },
    header1: {
      fontFamily: '"Roboto"',
      fontSize: '1.875rem',
      fontWeight: 700,
      lineHeight: '120%',
    },
    subheaderContainer: {
      padding: '1rem 0',
    },
    paragraph: {
      fontFamily: '"Roboto"',
      fontWeight: 400,
      fontSize: '0.9375',
      lineHeight: '140%',
    },
    gradientDivider: {
      width: '100%',
      height: 5,
      background: 'linear-gradient(90deg, #0f113d 0%, #326de6 65.1%)',
      marginBottom: '1.375rem',
    },
    featuresTry: {
      paddingTop: '1rem',
      fontFamily: '"Roboto"',
      fontSize: '0.9375rem',
      fontWeight: 700,
      lineHeight: '200%',
    },
    header2: {
      fontFamily: '"Roboto"',
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '125%',
      letterSpacing: 0,
      textAlign: 'left',
      paddingBottom: '1.25rem',
    },
    featuresBulletPoints: {
      listStyleType: 'disc',
      padding: '0 1rem 0 1.25rem',
      fontFamily: '"Roboto"',
      fontSize: '0.8125rem',
      fontWeight: 400,
      lineHeight: '160%',
    },
    featuresHeader: {
      fontFamily: '"Roboto"',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '200%',
    },
  };

  useMaskPasswordFields(document.body);

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        <a
          href={process.env.scyllaUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo
            alt="Scylla Logo"
            style={styles.logo}
            variant="horizontal"
            color="default"
          />
        </a>
        <div>
          <h1 style={styles.header1}>Get Started with ScyllaDB Cloud</h1>
          <div style={styles.subheaderContainer}>
            <p style={styles.paragraph}>
              Build real-time applications at global scale with the industry's
              fastest and most powerful fully managed NoSQL DBaaS.
            </p>
          </div>
        </div>
        <div style={styles.gradientDivider} />
        <div style={styles.header2}>
          High throughput.
          <br />
          Low latency.
          <br />
          Top price-performance.
        </div>
        <div>
          <div style={styles.featuresHeader}>
            ScyllaDB Cloud benefits include:
          </div>
          <ul style={styles.featuresBulletPoints}>
            <li>
              Single-digit P99 latency for millions of operations per second
            </li>
            <li>API-compatible with Apache Cassandra and Amazon DynamoDB</li>
            <li>Access 50+ regions across AWS and GCP</li>
            <li>Advanced monitoring</li>
            <li>Fully-managed with 24 x 7 support</li>
            <li>Free trial for new signups</li>
          </ul>
          <div style={styles.featuresTry}>Try for Free!</div>
        </div>
      </div>
    </div>
  );
}
