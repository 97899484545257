import { createContext, useCallback } from 'react';
import useSWR from 'swr';

import LoadingPage from 'components/common/LoadingPage';
import { axiosFetcher } from 'hooks/fetch';

export const FeaturesContext = createContext({
  featureFlags: [],
  hasFeature: () => {},
});

const apiUrl = process.env.apiUrl;

function FeaturesProvider({ children, featuresOverride }) {
  const {
    data: featureFlags,
    error,
    isLoading,
  } = useSWR(!featuresOverride && `${apiUrl}/info/features`, axiosFetcher);

  if (error) {
    console.error(error);
  }

  const hasFeature = useCallback(
    feature =>
      (featuresOverride ? featuresOverride : featureFlags)?.includes(feature),
    [featureFlags, featuresOverride]
  );

  return !isLoading ? (
    <FeaturesContext.Provider
      value={{
        featureFlags:
          error || featuresOverride ? featuresOverride : featureFlags,
        hasFeature,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  ) : (
    <LoadingPage />
  );
}

export default FeaturesProvider;
