import PropTypes from 'prop-types';

/*
    Notifications translations
*/

const ADD_DC = ({ onSupportClick, isSupportLoading }) => (
  <span>
    Your request to add a new data center has been received.
    <br />
    The ScyllaDB Cloud team will process it within 48 hours.
    <br />
    For queries or urgent help, please{' '}
    {isSupportLoading ? (
      'open a support ticket'
    ) : (
      <a
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={onSupportClick}
      >
        open a support ticket
      </a>
    )}
    .
  </span>
);

ADD_DC.propTypes = {
  onSupportClick: PropTypes.func.isRequired,
};

export const SUCCESS = {
  LOGIN: {
    VERIFICATION: 'Your email is verified. Please sign in.',
    RESEND_VERIFICATION:
      "Verification was sent to your email. If you don't see it in your inbox, check your spam or junk folder.",
  },
  PASSWORD: {
    RESET:
      'Almost done!\n Please check your inbox for the password reset email',
    VERIFY: 'Your email has been verified successfully',
    CHANGED: 'Your password has been changed successfully',
  },
  CLUSTER: {
    DELETE: 'Cluster was deleted successfully',
    RENAME: 'Cluster was renamed successfully',
    RESIZE:
      'Your resize request was received and will be handled as soon as possible. You can close this window',
    EXTRACT: 'Cluster Metrics is now enabled',
    DISABLE: 'Cluster Metrics was successfully disabled',
    ADD_DC,
    EXTEND_TRIAL: 'Successfully extended cluster trial period',
  },
  ACCOUNT: {
    EDIT_PROFILE: 'Your profile has been updated successfully',
    SET_PAYMENT_METHOD: 'Your payment method has been updated successfully',
    CHANGE_PASSWORD: 'Your password has been changed successfully',
  },
  ACCOUNT_CREDENTIALS: {
    SUCCESS: 'Your request has been submitted',
  },
};

export const FAILURE = {
  LOGIN: {
    VERIFICATION: 'Your email verification has failed',
    RESEND_VERIFICATION: 'Failed to send verification to your email.',
  },
  STATUS: {
    EXPIRE: 'Your session has expired. please relogin',
  },
  PASSWORD: {
    VERIFY: 'Email Verification Failed',
  },
  CLUSTER: {
    BOOTSTRAP: 'Please try to create this cluster again',
    OWN_ACCOUNT_BOOTSTRAP:
      'Please fix the issue above and try to create this cluster again',
    RESIZE: 'Failed to load resize information',
    ADD_DC: 'Failed to load add data center information',
    ADD_DC_NOT_SUPPORTED: 'This cluster is not supporting multi data center',
    MONITOR_EMPTY_DC: 'Please select Data Center to show graphs.',
    DISABLE_METRICS: 'Failed to disable metrics. Please try again',
  },
  ACCOUNT: {
    BILLING_TOKEN: 'There was an error submitting your billing information.',
    CHANGE_PASSWORD: 'One or more password values are incorrect',
  },
};
