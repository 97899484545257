import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';

import { MODAL_ANALYTICS_TAGS } from 'components/common/modals';
import { GoogleAnalytics } from 'utils/analytics';

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [Component, setComponent] = useState(null);
  const [params, setParams] = useState(null);
  const [onHide, setOnHide] = useState(null);

  const showModal = useCallback(
    (ModalComponent, onHideFunc, params) => () => {
      setComponent(ModalComponent);

      // React set state can receive a function to be executed to evalute the new state
      setOnHide(() => onHideFunc);
      setParams(params);

      GoogleAnalytics.send('pageview', {
        page: `/modal/${MODAL_ANALYTICS_TAGS[ModalComponent.name]}`,
      });
    },
    [setComponent, setOnHide, setParams]
  );

  const modal = useMemo(
    () =>
      Component ? (
        <Component
          hideModal={(...args) => {
            if (onHide) {
              onHide(...args);
            }
            setComponent(null);
          }}
          params={params}
        />
      ) : null,
    [Component, params, onHide]
  );

  return (
    <ModalContext.Provider value={showModal}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
}

export function useShowModal() {
  return useContext(ModalContext);
}

export function withShowModal(WrappedComponent) {
  return function ComponentWithParams(props) {
    const show = useShowModal();

    const showModal = useCallback((...args) => show(...args)(), [show]);

    const componentProps = {
      ...props,
      showModal,
    };

    return <WrappedComponent {...componentProps} />;
  };
}

export default ModalProvider;
