export function extractFileFromResponse(response, customFilename) {
  // Due to the request being handled by js and not the browser, there will be no prompt for download even though content-disposition header is set
  const fileName =
    customFilename ||
    response.headers['content-disposition']
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .trim();

  promptBrowserDownload(response.data, fileName);
}

function promptBrowserDownload(data, fileName) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
