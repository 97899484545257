import { Button } from '@mui/material';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  whatsNewButton: {
    height: '40px',
    boxShadow: 'none',
    color: theme.colors.white,
    border: 'unset',
    '&:hover': {
      border: 'unset',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function openCloudUpdatesPage() {
  window.open(process.env.scyllaCloudUpdatesUrl, '_blank');
}

function WhatsNewButton({ classes = {} }) {
  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.whatsNewButton}
      data-testid="whatsNewButton"
      id="whatsNewButton"
      disableRipple
      onClick={openCloudUpdatesPage}
    >
      WHAT'S NEW?
    </Button>
  );
}

export default withJss(WhatsNewButton);
