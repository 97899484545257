import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

import {
  cloudAccountsKey,
  useDefaultAccountId,
  useOwnCloudAccount,
} from 'hooks/account';
import { useMutationRequest, useQueryRequest } from 'hooks/fetch';
import { CLOUD_PROVIDER_ID_BY_NAME } from 'services/constants';
import {
  cloudAccountAwsCloudPolicy,
  createCloudAccount,
  deleteCloudAccount,
  setCloudAccountProperties,
} from 'services/resources/account/cloud-account';

const apiUrl = process.env.apiUrl;

export function useCloudAccountAwsBoundaryPolicy() {
  const defaultAccountId = useDefaultAccountId();
  const { data: { id: cloudAccountId } = {} } = useOwnCloudAccount();

  const key =
    defaultAccountId &&
    cloudAccountId &&
    `${apiUrl}/account/${defaultAccountId}/cloud-account/${cloudAccountId}/aws-boundary-policy`;

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
  });

  return {
    data,
    ...rest,
  };
}

export function useGetCloudAccountAwsCloudPolicy() {
  const defaultAccountId = useDefaultAccountId();
  const { data: { id: cloudAccountId } = {} } = useOwnCloudAccount();

  const requester = token =>
    cloudAccountAwsCloudPolicy.call({
      accountId: defaultAccountId,
      cloudAccountId,
      cloudPolicyName: 'ScyllaCloud',
      token,
    });
  return useMutationRequest({ requester, fetchAtInit: true });
}

export function useCloudAccountStatusCheck() {
  const defaultAccountId = useDefaultAccountId();

  const { data: { id: cloudAccountId } = {} } = useOwnCloudAccount();

  const key =
    defaultAccountId &&
    cloudAccountId &&
    `${apiUrl}/account/${defaultAccountId}/cloud-account/${cloudAccountId}/check`;

  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useCloudAccountClusters() {
  const defaultAccountId = useDefaultAccountId();

  const { data: { id: cloudAccountId } = {} } = useOwnCloudAccount();

  const key =
    defaultAccountId &&
    cloudAccountId &&
    `${apiUrl}/account/${defaultAccountId}/cloud-account/${cloudAccountId}/clusters`;

  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useCreateCloudAccount(onSuccess) {
  const defaultAccountId = useDefaultAccountId();

  const { mutate } = useSWRConfig();

  const requester = token =>
    createCloudAccount.call({
      accountId: defaultAccountId,
      cloudProviderId: CLOUD_PROVIDER_ID_BY_NAME.AWS,
      token,
    });

  return useMutationRequest({
    requester,
    onSuccess: result => {
      mutate(cloudAccountsKey(defaultAccountId));

      if (onSuccess) {
        onSuccess(result);
      }
    },
  });
}

export function useDeleteCloudAccount() {
  const defaultAccountId = useDefaultAccountId();
  const requester = (cloudAccountId, token) =>
    cloudAccountId &&
    deleteCloudAccount.call({
      accountId: defaultAccountId,
      cloudAccountId,
      token,
    });
  return useMutationRequest({
    requester,
  });
}

export function useUpdateCloudAccountProperties() {
  const defaultAccountId = useDefaultAccountId();
  const { data: { id: cloudAccountId } = {} } = useOwnCloudAccount();
  const { mutate } = useSWRConfig();

  const refetchCloudAccount = useCallback(
    () =>
      mutate(
        defaultAccountId &&
          `${apiUrl}/account/${defaultAccountId}/cloud-account`
      ),
    [defaultAccountId, mutate]
  );

  const requester = (payload, token) =>
    cloudAccountId &&
    setCloudAccountProperties.call({
      accountId: defaultAccountId,
      cloudAccountId,
      ...payload,
      token,
    });

  return useMutationRequest({
    requester,
    onSuccess: refetchCloudAccount,
    onFailure: refetchCloudAccount,
  });
}
