import { Box, Grid, Slider, TextField } from '@mui/material';

import { transformToScaledValue } from 'components/calculator/services/calculator';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  slider: {
    color: theme.colors.azul,
    '& > .MuiSlider-markLabel': {
      fontSize: '0.875rem',
    },
    minWidth: '264px',
  },
  interactiveElementsContainer: {
    display: 'flex',
    gap: '2rem',
  },
  specGroup: {
    width: '400px',
  },
  specName: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
    paddingBottom: '1rem',
  },
  specInput: {
    '.Mui-focused > fieldset': {
      borderColor: `${theme.colors.electricEel} !important`,
      borderWidth: '1px !important',
    },
  },
  marks: {
    display: 'flex',
    '> :last-child': {
      marginLeft: 'auto',
    },
  },
}));

function SpecSlider({ spec, onChange, classes }) {
  const [specId, specValues] = spec;
  const isScaled = Boolean(specValues.scale);
  const inputValue = specValues.current || specValues.default;

  return (
    <Grid item className={classes.specGroup}>
      <div className={classes.specName}>{specValues.name}</div>
      <Box className={classes.interactiveElementsContainer}>
        <div>
          <Slider
            min={isScaled ? Math.log10(specValues.min) : specValues.min}
            max={isScaled ? Math.log10(specValues.max) : specValues.max}
            step={isScaled ? 0.02 : 1}
            name={specValues.name}
            value={isScaled ? Math.log10(inputValue) : inputValue}
            onChange={ev => {
              const newVal = isScaled
                ? transformToScaledValue(ev.target.value)
                : ev.target.value;
              onChange(specId, newVal);
            }}
            scale={specValues.scale}
            className={classes.slider}
          />
          <Box className={classes.marks}>
            <span>{specValues.marks[0]}</span>
            <span>{specValues.marks[1]}</span>
          </Box>
        </div>
        <TextField
          className={classes.specInput}
          value={inputValue}
          min={specValues.min}
          max={specValues.max}
          type="number"
          variant="outlined"
          onChange={ev => {
            onChange(specId, ev.target.value);
          }}
        />
      </Box>
    </Grid>
  );
}

export default withJss(SpecSlider);
