import { DialogActions } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  root: theme.extensions.paperFooter(theme),
  centered: {
    justifyContent: 'center',
  },
}));

function MainModalActions({ children, classes, centered }) {
  return (
    <DialogActions
      className={classNames(classes.root, {
        [classes.centered]: centered,
      })}
    >
      {children}
    </DialogActions>
  );
}

MainModalActions.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({}),
  centered: PropTypes.bool,
};

export default withJss(MainModalActions);
