import { AdminPortal } from '@frontegg/react';
import {
  Payment as PaymentIcon,
  AutoAwesomeMosaic as MosaicIcon,
  BuildSharp as BuildIcon,
  SettingsSharp as SettingsIcon,
  Logout as LogoutIcon,
  EditNotifications as EditNotificationsIcon,
  DataUsage as DataUsageIcon,
} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  Chip,
} from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import AccountSelection from 'components/layouts/app-layout/header/AccountSelection';
import AppRoutes from 'components/pages';
import { useHasFeature } from 'components/providers/features/with-features';
import withStyles from 'components/withStylesAdapter';
import { PERMISSIONS } from 'definitions/user-profile';
import { useAuthentication } from 'hooks/authentication';
import { usePermission } from 'hooks/utils';
import { infoKey } from 'services/resources/me';
import { useMaskPasswordFields } from 'utils/analytics';
import logger from 'utils/logger';

import ResourcesLinks from './Resources';

const withJss = withStyles(theme => ({
  dropdownButton: {
    gap: '0.75rem',
    color: theme.colors.alabaster,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      gap: '0',
    },
  },
  avatar: {
    fontWeight: 400,
    backgroundColor: theme.colors.karimunBlue,
  },
  buttonText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    lineHeight: '1.25rem',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonSubtext: {
    fontWeight: 400,
  },
  menu: {
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  menuItem: {
    position: 'relative',
    fontSize: theme.typography.pxToRem(14),
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    '&.Mui-disabled': {
      opacity: 'unset',
    },
    lineHeight: '1.25rem',
  },
  menuItemSubtext: {
    color: theme.colors.flintstone,
  },
  userMenuItem: {
    lineHeight: '1.5rem',
  },
  divider: {
    '&.MuiDivider-root': {
      margin: 0,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    width: '100%',
    height: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  menuIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: '1.6rem',
      fontSize: '1.1rem',
    },
  },
  betaBadge: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.colors.successPale,
    color: theme.colors.success,
  },
}));

function createAvatarInitials(name) {
  const splitName = name?.split(' ');
  const firstName = splitName?.at(0);
  const lastName = splitName?.at(-1);

  return (
    firstName?.charAt(0)?.toUpperCase() + lastName?.charAt(0)?.toUpperCase()
  );
}

function UserInfoDropdown({ classes = {} }) {
  const { mutate } = useSWRConfig();
  const {
    logout,
    userProfile: { name, email, accounts, defaultAccountId } = {},
  } = useAuthentication();

  const shouldShowByok = usePermission(PERMISSIONS.canViewBYOK);
  const canUpdateBillingDetails = usePermission(
    PERMISSIONS.canUpdateBillingDetails
  );
  const canManageCloudAccount = usePermission(
    PERMISSIONS.canManageCloudAccount
  );
  const canManageMaintenanceWindows = usePermission(
    PERMISSIONS.canManageMaintenanceWindows
  );
  const canManageNotificationEmail = usePermission(
    PERMISSIONS.canManageNotificationEmail
  );

  const isUsageFfOn = useHasFeature('USAGE');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function preloadRoutes() {
    AppRoutes.Billing.preload();
    AppRoutes.ManagedResources.preload();
  }

  function refetchUserData() {
    mutate(infoKey);
  }

  function addAdminPortalClosedHandler() {
    const pushState = window.history.pushState;
    window.history.pushState = function (...args) {
      pushState.apply(window.history, args);
      if (window.location.hash === '') {
        refetchUserData();
        window.history.pushState = pushState;
      }
    };
  }

  // workaround for menu not closing after navigating to a route not yet loaded by suspense/lazy loading
  useLayoutEffect(() => {
    if (anchorEl === null) {
      setAnchorEl(undefined);
    }
  }, [anchorEl]);

  function openMenu(ev) {
    setAnchorEl(ev.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function logoutUser() {
    logger.info({
      prefix: 'Logout',
      message: 'user logged out on logout button click',
    });
    logout();
  }

  const currentAccountName = accounts?.find(
    a => a.id === defaultAccountId
  )?.name;

  const fronteggRoot = document.getElementById(
    'frontegg-admin-portal-container-default'
  );

  useMaskPasswordFields(fronteggRoot?.shadowRoot);

  return (
    <>
      <Button
        endIcon={<ArrowDropDownIcon />}
        className={classes.dropdownButton}
        data-testid="userDropdownButton"
        onClick={openMenu}
        onMouseEnter={preloadRoutes}
      >
        <Avatar variant="rounded" className={classes.avatar}>
          {createAvatarInitials(name)}
        </Avatar>
        <Box className={classes.buttonText}>
          <div data-testid="username">{name}</div>
          <div
            className={classes.buttonSubtext}
            data-testid="currentAccountName"
          >
            {currentAccountName}
          </div>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        className={classes.menu}
        data-testid="userDropdownMenu"
      >
        <MenuItem className={classes.menuItem} onClick={closeMenu} disabled>
          <Box className={classes.userMenuItem}>
            <div data-testid="dropdownUsername">{name}</div>
            <div
              className={classes.menuItemSubtext}
              data-testid="dropdownEmail"
            >
              {email}
            </div>
          </Box>
        </MenuItem>
        <Divider className={classes.divider} />
        {canUpdateBillingDetails && (
          <MenuItem
            className={classes.menuItem}
            onClick={closeMenu}
            data-testid="dropdownBilling"
          >
            <Link className={classes.link} to="/billing">
              <ListItemIcon className={classes.menuIcon}>
                <PaymentIcon fontSize="inherit" />
              </ListItemIcon>
              Billing
            </Link>
          </MenuItem>
        )}
        {(canManageCloudAccount || shouldShowByok) && (
          <MenuItem
            className={classes.menuItem}
            onClick={closeMenu}
            data-testid="dropdownManagedResources"
          >
            <Link className={classes.link} to="/managed-resources">
              <ListItemIcon className={classes.menuIcon}>
                <MosaicIcon fontSize="inherit" />
              </ListItemIcon>
              Managed Resources
            </Link>
          </MenuItem>
        )}
        {canManageNotificationEmail && (
          <MenuItem
            className={classes.menuItem}
            onClick={closeMenu}
            data-testid="dropdownNotificationPreferences"
          >
            <Link className={classes.link} to="/notifications">
              <ListItemIcon className={classes.menuIcon}>
                <EditNotificationsIcon fontSize="inherit" />
              </ListItemIcon>
              Notification Preferences
            </Link>
          </MenuItem>
        )}
        {canManageMaintenanceWindows && (
          <MenuItem
            className={classes.menuItem}
            onClick={closeMenu}
            data-testid="dropdownMaintenance"
          >
            <Link className={classes.link} to="/maintenance-windows">
              <ListItemIcon className={classes.menuIcon}>
                <BuildIcon fontSize="inherit" />
              </ListItemIcon>
              Maintenance Windows
            </Link>
          </MenuItem>
        )}
        <MenuItem
          className={classes.menuItem}
          data-testid="dropdownSettings"
          onClick={() => {
            addAdminPortalClosedHandler();
            AdminPortal.show();
            closeMenu();
          }}
        >
          <ListItemIcon className={classes.menuIcon}>
            <SettingsIcon fontSize="inherit" />
          </ListItemIcon>
          Settings
        </MenuItem>
        {isUsageFfOn && (
          <MenuItem
            className={classes.menuItem}
            onClick={closeMenu}
            data-testid="dropdownUsage"
          >
            <Link className={classes.link} to="/usage">
              <ListItemIcon className={classes.menuIcon}>
                <DataUsageIcon fontSize="inherit" />
              </ListItemIcon>
              <span>Usage</span>
              <Chip label="Beta" size="small" className={classes.betaBadge} />
            </Link>
          </MenuItem>
        )}
        <Divider className={classes.divider} />
        <ResourcesLinks classes={classes} />
        <Divider className={classes.divider} />
        {accounts?.length > 1 && [
          <AccountSelection classes={classes} key="account-selector" />,
          <Divider
            className={classes.divider}
            key="account-selector-divider"
          />,
        ]}
        <MenuItem
          className={classes.menuItem}
          onClick={logoutUser}
          data-testid="dropdownSignOut"
        >
          <ListItemIcon className={classes.menuIcon}>
            <LogoutIcon fontSize="inherit" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
}

export default withJss(UserInfoDropdown);
