import { Box } from '@mui/material';

import withStyles from 'components/withStylesAdapter';
const withJss = withStyles(theme => ({
  root: {
    color: theme.colors.adamantineBlue,
    fontSize: theme.typography.pxToRem(12),
    paddingTop: '0.5rem',
  },
  oval: {
    fontSize: '1.5rem',
    position: 'relative',
    top: theme.typography.pxToRem(5),
  },
}));

function DcChangeSummary({
  currentNodesCount,
  newNodesCount,
  currentInstance,
  newInstance,
  showStorage,
  classes,
}) {
  const nodesChanged = newNodesCount && currentNodesCount !== newNodesCount;
  const instanceChanged = newInstance && currentInstance !== newInstance;
  return nodesChanged || instanceChanged ? (
    <Box className={classes.root}>
      Changes:{' '}
      {instanceChanged
        ? `${currentInstance?.instance.externalId}${
            showStorage ? ` (${currentInstance?.instance.totalStorage} GB)` : ''
          } --> ${newInstance?.instance.externalId}${
            showStorage ? ` (${newInstance?.instance.totalStorage} GB)` : ''
          }`
        : ''}
      {instanceChanged && nodesChanged ? (
        <span className={classes.oval}> • </span>
      ) : (
        ''
      )}
      {nodesChanged
        ? `${currentNodesCount} x Nodes --> ${newNodesCount} x Nodes`
        : ''}
    </Box>
  ) : null;
}

export default withJss(DcChangeSummary);
