import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu } from '@mui/material';
import { useState } from 'react';

import Skeleton from 'components/common/skeleton';
import AccountSelection from 'components/layouts/app-layout/header/AccountSelection';
import withStyles from 'components/withStylesAdapter';
import { useAuthentication } from 'hooks/authentication';

const withJss = withStyles(theme => ({
  button: {
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  endIcon: {
    marginLeft: 0,
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(14),
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    '&.Mui-disabled': {
      opacity: 'unset',
    },
    lineHeight: '1.25rem',
  },
}));

function AccountSelectionDropdown({ classes }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { userProfile: { accounts, defaultAccountId } = {}, isLoading } =
    useAuthentication();

  const handleClick = event => {
    if (accounts?.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const currentAccountName = accounts?.find(
    a => a.id === defaultAccountId
  )?.name;

  return (
    <Skeleton fullSize isLoading={isLoading}>
      <Button
        id="account-button"
        className={classes.button}
        onClick={handleClick}
        endIcon={accounts?.length > 1 && <ArrowDropDownIcon />}
        classes={{
          endIcon: classes.endIcon,
        }}
      >
        {currentAccountName}
      </Button>
      {accounts?.length > 1 ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{
            'aria-labelledby': 'account-button',
          }}
        >
          <AccountSelection classes={classes} />
        </Menu>
      ) : null}
    </Skeleton>
  );
}

export default withJss(AccountSelectionDropdown);
