import Color from 'color2';

import * as colors from './colors';
import * as fonts from './fonts';

export const resetButton = {
  outline: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  '&[disabled]': {
    cursor: 'default',
  },
};

export const launchButton = {
  border: 'none',
  color: colors.white,
  borderRadius: 4,
  padding: '12px 26px',
  transition: 'background-color 200ms ease',
  '&:not([disabled])': {
    cursor: 'pointer',
  },
};

export const borderedInput = {
  border: `1px solid ${colors.whiteLilac}`,
  fontSize: fonts.pxToRem(13),
  textIndent: 10,
  borderRadius: 3,
};

export const spinner = {
  position: 'absolute',
  right: '10px',
};

export const boxBorder = {
  borderRadius: 6,
  backgroundColor: colors.white,
  border: `1px solid ${colors.mystic}`,
  padding: '16px 24px 16px 24px',
};

export const boxButton = {
  width: 37,
  height: 20,
  minWidth: 0,
  minHeight: 0,
  fontSize: fonts.pxToRem(10),
  padding: 0,
  margin: 0,
  color: colors.baliHai,
  fontWeight: 'normal',
  borderColor: colors.mystic,
};

export const hyperLink = {
  display: 'inline-block',
  '&:hover': {
    textDecoration: 'underline',
  },
};

export const modalTitle = {
  color: colors.black,
  fontWeight: 500,
  fontSize: fonts.pxToRem(12),
};

export const modalTitleBig = {
  color: colors.black,
  fontWeight: 500,
  fontSize: fonts.pxToRem(20),
  lineHeight: fonts.pxToRem(32),
};

export const customButton = (color = colors.monza) => ({
  backgroundColor: color,
  color: colors.white,
  '&:hover': {
    backgroundColor: Color(color).darken(0.2).hexString(),
  },
});

export const badge = (color = colors.caribbeanGreen) => ({
  color,
  border: `1px solid ${color}`,
  borderRadius: '3px',
  padding: '3px 10px',
});

export const helperTextButton = (
  color = colors.comet,
  alphaRegular = 0.1,
  alphaHover = 0.2
) => ({
  margin: ['0.25em', 0],
  padding: [0, '0.25em'],
  minHeight: 'auto',
  textDecoration: 'underline',
  fontSize: 'inherit',
  fontWeight: 500,
  color,
  backgroundColor: Color(color).alpha(alphaRegular).rgbString(),

  '&:hover': {
    backgroundColor: Color(color).alpha(alphaHover).rgbString(),
  },
});

export const inactive = {
  opacity: 0.5,
  pointerEvents: 'none',
};

export const infoBox = {
  position: 'relative',
  borderRadius: 6,
  padding: '28px 36px',
  backgroundColor: colors.whisper,
};

export const responsivePadding = (theme, qty = 2) => ({
  padding: theme.contentPadding,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(qty),
    width: 'auto',
  },
});

export const responsiveFlexColumn = (theme, media = 'md') => ({
  [theme.breakpoints.down(media)]: {
    height: 'auto',
    flexDirection: 'column',
  },
});

export const responsiveAccount = (theme, width) => {
  let actualWidth = width;
  switch (width) {
    case 'sm':
      actualWidth = 608;
      break;
    case 'md':
      actualWidth = 860;
      break;
    default:
      actualWidth = width;
  }
  return {
    ...responsivePadding(theme),
    display: 'inline-block',
    width: actualWidth,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: 'auto',
    },
  };
};

export const paperFooter = theme => ({
  padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(3)}`,
});

export const buttonsContainer = (theme, spacing = theme.spacing(1)) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  '& > *': {
    margin: `0 ${spacing}`,
  },
  'button:first-of-type': {
    marginRight: 0,
  },
  'button:last-of-type': {
    marginLeft: 0,
  },
});

export const flexBox = ({ flexDirection = 'row', ...restFlex }) => ({
  display: 'flex',
  flexDirection,
  ...restFlex,
});

export const link = {
  color: colors.pictonBlue,
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'inherit',
  },
};

export const subHeaderMenu = (theme, color = colors.sanMarino) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content max-content',
  justifyContent: 'space-between',
  alignItems: 'end',
  backgroundColor: color,
  padding: `0 ${theme.contentVPadding}`,
  '@container subheader (max-width: 650px)': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'max-content max-content',
    '& > :first-of-type': {
      order: 2,
    },
    '& > :last-of-type': {
      order: 1,
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: `0 ${theme.spacing(2)}`,
  },
});

export const accountTitle = theme => ({
  color: theme.colors.mineShaft,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.colors.catskillWhite}`,
});

export const textSymbol = {
  fontSize: '1.6em',
  lineHeight: '0.675em',
  verticalAlign: 'bottom',
};

export const iconButton = {
  width: 'auto',
  height: 'auto',
};

export const grayButton = theme => ({
  background: `linear-gradient(${theme.colors.white}, ${theme.colors.mystic})`,
  borderColor: theme.colors.mercury,
});

export const responsiveFieldRow = theme => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: '35% 50%',
  gridGap: theme.spacing(12),
  margin: `${theme.spacing(1)} 0`,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content',
    gridGap: theme.spacing(2),
  },
});
