import * as colors from './colors';

export const fontFamily = 'Roboto, Helvetica, sans-serif';

const fontSize = 14; // px
// Tell Material-UI what's the font-size on the html element.
// 16px is the default font-size used by browsers.
const htmlFontSize = 16;
const coef = fontSize / 14;

export const pxToRem = size => `${(size / htmlFontSize) * coef}rem`;

export const column = {
  fontFamily,
  fontWeight: '500',
  fontSize: pxToRem(10),
};

export const caption = {
  fontFamily,
  fontWeight: '400',
  fontSize: pxToRem(12),
};

export const popupLabel = {
  fontFamily,
  fontWeight: 'normal',
  fontSize: pxToRem(10),
};

export const popupValue = {
  fontFamily,
  fontWeight: '500',
  fontSize: pxToRem(10),
};

export const sectionTitle = {
  fontFamily,
  fontWeight: '500',
  fontSize: pxToRem(12),
};

export const tableData = {
  fontFamily,
  fontWeight: 'normal',
  fontSize: pxToRem(12),
};

export const fieldLabel = {
  fontFamily,
  fontWeight: 'normal',
  fontSize: pxToRem(13),
  color: colors.mako,
};

export const paragraphTitle = {
  fontFamily,
  fontWeight: '500',
  fontSize: pxToRem(13),
};

export const textLink = {
  fontFamily,
  fontWeight: 'normal',
  fontSize: pxToRem(13),
};

export const text = {
  fontFamily,
  fontWeight: 'normal',
  fontSize: pxToRem(14),
};

export const statsLabel = {
  fontFamily,
  fontWeight: '500',
  fontSize: pxToRem(14),
};

export const breadcrumbTitle = {
  fontFamily,
  fontWeight: '200',
  fontSize: pxToRem(24),
  color: colors.white,
};

export const breadcrumbIndicator = {
  fontFamily,
  fontWeight: '200',
  fontSize: pxToRem(16),
  color: colors.periwinkleGray,
};

export const modalButton = {
  fontSize: pxToRem(14),
  fontWeight: 400,
  textTransform: 'uppercase',
};

export const alertText = {
  fontSize: pxToRem(15),
  fontWeight: 500,
};

export const inputField = {
  fontFamily,
  fontWeight: '400',
  fontSize: pxToRem(16),
  color: colors.baliHai,
};
