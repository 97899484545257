export const LOGIN_STATUS_VALID = 'VALID';
export const LOGIN_STATUS_TOTP_PREVALIDATE = 'TOTP_PREVALIDATE';

export const LOGIN_TYPE_BASIC = 'BASIC';
export const LOGIN_TYPE_MFA_TOTP = 'MFA_TOTP';

export const PERMISSIONS = {
  canCreateCluster: 'canCreateCluster',
  canResizeCluster: 'canResizeCluster',
  canDeleteCluster: 'canDeleteCluster',
  canAddDC: 'canAddDC',
  canEnableExtractMetrics: 'canEnableExtractMetrics',
  canDisableExtractMetrics: 'canDisableExtractMetrics',
  canMonitorCluster: 'canMonitorCluster',
  canManageVPCPeering: 'canManageVPCPeering',
  canUpdateClusterEncryptionMode: 'canUpdateClusterEncryptionMode',
  canUpdateClusterName: 'canUpdateClusterName',
  canManageAllowedIPs: 'canManageAllowedIPs',
  canUpdateBillingDetails: 'canUpdateBillingDetails',
  canListCloudAccounts: 'canListCloudAccounts',
  canViewCloudAccount: 'canViewCloudAccount',
  canManageCloudAccount: 'canManageCloudAccount',
  canCheckCloudAccount: 'canCheckCloudAccount',
  canManageMaintenanceWindows: 'canManageMaintenanceWindows',
  canManageBYOK: 'canManageBYOK',
  canViewBYOK: 'canViewBYOK',
  canManageClusterConnections: 'canManageClusterConnections',
  canListClusterConnections: 'canListClusterConnections',
  canManageNotificationEmail: 'canManageNotificationEmail',

  // BELOW ARE NOT USED/IMPLEMENTED PERMISSIONS
  canManageOwnAccount: 'canManageOwnAccount',
  canOpenScyllaTicket: 'canOpenScyllaTicket',

  canInviteUser: 'canInviteUser',
  canAddAccountManagerRole: 'canAddAccountManagerRole',
  canRevokeAccountManagerRole: 'canRevokeAccountManagerRole',
  canAddNonAccountManagerRole: 'canAddNonAccountManagerRole',
  canRevokeNonAccountManagerRole: 'canRevokeNonAccountManagerRole',
  canCreateRole: 'canCreateRole',
  canGrantRolePermission: 'canGrantRolePermission',
  canManageInvite: 'canManageInvite',
  canViewBillingReports: 'canViewBillingReports',
};
