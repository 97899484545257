import {
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  buttonsContainer: theme.extensions.buttonsContainer(theme),
}));

function AddTgwActions({
  classes,
  onSubmit,
  onCancel,
  isLoading,
  isClusterLocked,
}) {
  return (
    <MainModalActions>
      <div className={classes.buttonsContainer}>
        {!isClusterLocked && (
          <MainModalActionButton
            id="addTgwSubmitButton"
            type={MainModalActionButton.Types.MAJOR}
            onClick={onSubmit}
            isLoading={isLoading}
            data-testid="addTgwActionSubmitButton"
          >
            ADD TRANSIT GATEWAY
          </MainModalActionButton>
        )}
        <MainModalActionButton
          id="addTgwCancelBtn"
          data-testid="addTgwActionCancelButton"
          type={MainModalActionButton.Types.MINOR}
          onClick={onCancel}
        >
          CANCEL
        </MainModalActionButton>
      </div>
    </MainModalActions>
  );
}

export default withJss(AddTgwActions);
