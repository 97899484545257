import { range, sortBy } from 'lodash/fp';

import { CLOUD_PROVIDER_ID_BY_NAME } from 'services/constants';

import {
  HOURS_PER_MONTH,
  PERF_MULTIPLIER_BY_FAMILY_GROUP,
  MAX_NUMBER_OF_NODES,
  TB_TO_GB_MULTIPLIER,
} from './pricing-constants.ts';

function excludeDevelopmentInstances(instance) {
  return instance.environment !== 'DEVELOPMENT';
}

// https://github.com/scylladb/siren-frontend/issues/3984
function excludeI3Instances(instance) {
  return instance.instanceFamily !== 'i3';
}

export function mapInstanceProperties(instance) {
  return {
    name: instance.externalId,
    instanceFamily: instance.instanceFamily,
    vcpu: instance.cpuCount,
    memory: instance.memory / 1024,
    storage: instance.totalStorage,
    cloudProviderId: instance.cloudProviderId,
    instanceId: instance.id,
    computePrice: {
      ondemand: HOURS_PER_MONTH * instance.instanceCostHourly,
    },
    licensePrice: {
      ondemand: HOURS_PER_MONTH * instance.subscriptionCostHourly,
    },
  };
}

// creates initial data structure with fetched 'on-demand' prices
export function prepareInstanceInitData(instances) {
  return instances
    .filter(excludeDevelopmentInstances)
    .filter(excludeI3Instances)
    .map(mapInstanceProperties);
}

export interface WorkloadSpec {
  reads: number;
  writes: number;
  storage: number;
  itemSize: number;
}

export interface PerfModeData {
  reads: number;
  writes: number;
}

export const standardVcpuPerf = {
  reads: 15000,
  writes: 15000,
};

interface ResourceSpec {
  readonly vcpu: number;
  readonly memory: number;
  readonly storage: number;
}

interface ClusterSpec {
  readonly nodes: number;
  readonly instanceType: InstanceTypeSpec;
}

interface NodePricing {
  readonly ondemand: MonthlyPrice;
}

type MonthlyPrice = number;

interface InstanceTypeSpec extends ResourceSpec {
  readonly name: string;
  readonly computePrice: NodePricing;
  readonly licensePrice: NodePricing;
}

type Cloud = 'aws' | 'gcp';

function licensePrice(cluster: ClusterSpec, isReserved: boolean): MonthlyPrice {
  return cluster.nodes * cluster.instanceType.licensePrice.ondemand;
}

function ondemandPrice(cluster: ClusterSpec): MonthlyPrice {
  return cluster.nodes * cluster.instanceType.computePrice.ondemand;
}

function clusterResources(cluster: ClusterSpec = {}): ResourceSpec {
  return {
    storage: cluster.instanceType?.storage * cluster.nodes,
    vcpu: cluster.instanceType?.vcpu * cluster.nodes,
    memory: cluster.instanceType?.memory * cluster.nodes,
  };
}

/*
The following is experimental result, function is based on model regressions done on a series of benchmark results. itemSize in kb.
*/
function itemSizePerfFactor(itemSize: number, internalParams): number {
  const a = internalParams.performanceFactorA;
  const b = internalParams.performanceFactorB;
  return a / (itemSize + b);
}

function getInstancePerfMultiplier(instance): number {
  return PERF_MULTIPLIER_BY_FAMILY_GROUP[instance?.instanceFamily] || 1;
}

/* Cluster size recommendations based on the optimization target:
- performance (CPU) - select nodes with enough storage and max cpu (note that the performance for i4i instances is doubled)
- storage - select nodes with enough cpu and max storage
- cost - select nodes with just enough cpu and storage, even if smaller nodes
*/
function selectClusterConfigs(specs: ResourceSpec, instances): ClusterSpec[] {
  return instances
    .map(instanceType => {
      const nodes =
        range(1, MAX_NUMBER_OF_NODES + 1).find(
          n =>
            instanceType.vcpu * n * getInstancePerfMultiplier(instanceType) >=
              specs.vcpu &&
            instanceType.memory * n >= specs.memory &&
            instanceType.storage * n >= specs.storage
        ) || 0;
      return { instanceType, nodes };
    })
    .filter(({ nodes }) => nodes > 0);
}

export function selectClusterInstances(
  workload: WorkloadSpec,
  replicationFactor: number,
  perf: PerfModeData,
  instances,
  cloudProvider,
  internalParams
): ClusterSpec | undefined {
  const diskSpace = workload.storage * internalParams.compactionOverhead;
  const recommendedResources: ResourceSpec = {
    vcpu:
      (workload.reads /
        perf.reads /
        internalParams.performanceDegradationReads +
        workload.writes /
          perf.writes /
          internalParams.performanceDegradationWrites) /
      itemSizePerfFactor(workload.itemSize, internalParams),
    storage: diskSpace,
    memory: Math.ceil(workload.storage / internalParams.ramToDataRatio),
  };

  const minimalResources: ResourceSpec = {
    ...recommendedResources,
    memory: Math.ceil(diskSpace / internalParams.ramToDiskRatio),
  };

  const selectedProviderId = CLOUD_PROVIDER_ID_BY_NAME[cloudProvider];
  const instancesForTheProvider = instances.filter(
    i => i.cloudProviderId === selectedProviderId
  );

  const recommendedConfigs = selectClusterConfigs(
    recommendedResources,
    instancesForTheProvider
  );
  const minimalConfigs = selectClusterConfigs(
    minimalResources,
    instancesForTheProvider
  );

  const lowestPrice = Math.min(...minimalConfigs.map(ondemandPrice));

  const bestConfig = (configs: ClusterSpec[]) =>
    sortBy(
      'nodes',
      configs.filter(
        spec =>
          ondemandPrice(spec) <
          lowestPrice * internalParams.bestConfigSelectionThreshold
      )
    )[0];

  const selectedConfig =
    bestConfig(recommendedConfigs) || bestConfig(minimalConfigs);

  if (!selectedConfig) {
    return;
  }

  return {
    ...selectedConfig,
    nodes: selectedConfig?.nodes * replicationFactor,
  };
}

export function calculateClusterCapacity(
  cluster: ClusterSpec,
  replicationFactor: number,
  internalParams
) {
  const perf = standardVcpuPerf;
  const totalResources = clusterResources(cluster);
  const dataset =
    totalResources.storage /
    replicationFactor /
    internalParams.compactionOverhead;
  const peakLoad =
    (totalResources.vcpu *
      (perf.writes + perf.reads) *
      getInstancePerfMultiplier(cluster.instanceType)) /
    2 /
    replicationFactor;
  const sustainedLoad =
    (totalResources.vcpu *
      (perf.writes * internalParams.performanceDegradationReads +
        perf.reads * internalParams.performanceDegradationWrites) *
      getInstancePerfMultiplier(cluster.instanceType)) /
    2 /
    replicationFactor;

  return { sustainedLoad, peakLoad, dataset, ...totalResources };
}

export function computeSuggestedCluster(
  workload: WorkloadSpec,
  replicationFactor: number,
  instances,
  cloud: Cloud = 'aws',
  internalParams
) {
  const workloadAdjusted = {
    ...workload,
    storage: workload?.storage * TB_TO_GB_MULTIPLIER,
  };

  const perf = standardVcpuPerf;
  // currently, Scylla requires each replica to be in a different AZ
  const replicationTraffic =
    ((HOURS_PER_MONTH *
      3600 *
      ((workloadAdjusted.reads + workloadAdjusted.writes) *
        workloadAdjusted.itemSize *
        (replicationFactor - 1))) /
      1e6) *
    internalParams.throughputFactor;
  const dataTransfer = replicationTraffic * internalParams.awsDataTransferPrice;

  const cluster = selectClusterInstances(
    workloadAdjusted,
    replicationFactor,
    perf,
    instances,
    cloud,
    internalParams
  );

  if (!cluster) {
    return { cluster: {}, prices: [] };
  }

  const prices = [
    {
      id: 'ondemand',
      name: 'On demand',
      compute: ondemandPrice(cluster),
      license: licensePrice(cluster, false),
    },
  ].map(priceSpec => {
    const { compute, license } = priceSpec;
    return {
      ...priceSpec,
      dataTransfer,
      total: compute + license, // In the new pricing, data transfer fees included in subscription price
    };
  });

  return { prices, cluster };
}
