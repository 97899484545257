import { isEmpty, isNil, sortBy } from 'lodash/fp';
import {
  useContext,
  useEffect,
  useReducer,
  createContext,
  useCallback,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { AddDCDataContext } from 'components/pages/cluster-details/add-dc/context';
import { useCloudAccounts } from 'hooks/account';
import { useClusterMaintenanceWindowsInfo } from 'hooks/maintenanceWindows';
import { isAws } from 'services/cluster';
import { CLOUD_PROVIDER_ID_BY_NAME, KEY_PROVIDERS } from 'services/constants';
import {
  ALTERNATOR_ISOLATION_POLICY_NAMES,
  BROADCAST_TYPE,
  USER_API_INTERFACE,
  DEAL_TYPES,
} from 'services/constants';
import { getActiveCloudAccounts, getDefaultCredentialId } from 'services/utils';
import { validateIPsArray, fixCidr } from 'services/validators';
import { usePrevious } from 'utils/hooks';

import {
  getNewClusterDefaults,
  getUrlDeal,
  useRegions,
  useRegionInstances,
  useScyllaVersions,
  useClientIp,
  useUrlDeal,
} from './deployment';
import { FORM_DEFAULT_CONFIG } from './form';
import { createFormDataReducer } from './reducer';

const getValidInstances = instances => {
  const deal = getUrlDeal();
  const isFreeTierDeal = deal === DEAL_TYPES.FREE_TRIAL;
  const validInstances = isFreeTierDeal
    ? instances.filter(i => i.freeTier)
    : instances;

  return validInstances;
};

export const getDefaultGroupInstance = instances => {
  const validInstances = getValidInstances(instances);

  return sortBy('id', validInstances)[0];
};

export const getDefaultInstance = (instances, cloudProviderId) => {
  const validInstances = getValidInstances(instances);

  const defaults = getNewClusterDefaults();

  const defaultInstanceBySorting = sortBy('id', validInstances)[0];

  const defaultInstance =
    cloudProviderId === CLOUD_PROVIDER_ID_BY_NAME.AWS
      ? validInstances.find(i => i.id === defaults.instanceId) ||
        defaultInstanceBySorting
      : defaultInstanceBySorting;

  return defaultInstance;
};

const NewClusterDataContext = createContext();

function NewClusterDataProvider({ children }) {
  const deal = getUrlDeal();
  const isFreeTierDeal = deal === DEAL_TYPES.FREE_TRIAL;
  const defaults = getNewClusterDefaults();
  const [state, dispatch] = useReducer(createFormDataReducer(defaults), {
    ...defaults,
    freeTier: isFreeTierDeal,
  });

  // Run all side effects for setting defaults - cloud provider / free tier change etc
  useNewClusterDefaults(state, dispatch);

  // monitor route changes in the new cluster context
  useNewClusterRouteChecking();

  const form = useForm(FORM_DEFAULT_CONFIG);

  const value = { state, dispatch };
  return (
    <NewClusterDataContext.Provider value={value}>
      <FormProvider {...form}>{children}</FormProvider>
    </NewClusterDataContext.Provider>
  );
}

function refreshIfRevisitingNewCluster(oldPath, newPath) {
  if (
    oldPath !== newPath &&
    oldPath?.match(/new\/[\d]+\/launch/g) &&
    newPath.includes('clusters/new')
  ) {
    // performing page navigation with reload when revisiting directly from /launch page
    window.location.href = newPath;
  }
}

function useNewClusterRouteChecking() {
  const location = useLocation();
  const previousPath = usePrevious(location.pathname);

  useEffect(() => {
    refreshIfRevisitingNewCluster(previousPath, location.pathname);
  }, [previousPath, location]);
}

function useNewClusterDefaults(state, dispatch) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlKeyId = queryParams.get('keyId');
  const urlRegionId = Number(queryParams.get('regionId'));

  const { cloudProviderId, broadcastType, userApiInterface, scyllaVersionId } =
    state || {};
  const { regions, defaultRegionId } = useRegions(cloudProviderId, true);
  const { instances } = useRegionInstances(cloudProviderId, regions?.[0]?.id);
  const { scyllaVersions } = useScyllaVersions();
  const { data: maintenanceWindowsInfo } = useClusterMaintenanceWindowsInfo();
  const { clientIp } = useClientIp();
  const deal = useUrlDeal();
  const isFreeTier = deal === DEAL_TYPES.FREE_TRIAL;

  function setDefaultScyllaVersion() {
    if (scyllaVersions && isNil(scyllaVersionId)) {
      const defaultVersion = scyllaVersions.find(
        v => v.newCluster === 'ENABLED'
      );

      dispatch({
        type: 'update',
        payload: {
          scyllaVersionId: defaultVersion.id,
        },
      });
    }
  }

  useEffect(
    function setDefaultMaintenanceWindows() {
      dispatch({
        type: 'update',
        payload: {
          maintenanceWindows: maintenanceWindowsInfo?.default?.map(mw => ({
            rrule: mw.rrule,
            duration: mw.duration,
          })),
        },
      });
    },
    [maintenanceWindowsInfo, dispatch]
  );

  useEffect(
    function setEncryptionAtRest() {
      let encryptionAtRest = {};

      if (isAws(cloudProviderId)) {
        if (urlKeyId) {
          encryptionAtRest = {
            provider: KEY_PROVIDERS.CUSTOMER_KEY,
            keyID: urlKeyId,
          };
        } else {
          encryptionAtRest = {
            provider: KEY_PROVIDERS.SCYLLA_KEY,
          };
        }
      }

      dispatch({
        type: 'update',
        payload: {
          encryptionAtRest,
        },
      });
    },
    [dispatch, cloudProviderId, urlKeyId]
  );

  useEffect(
    function setFreeTier() {
      dispatch({
        type: 'update',
        payload: {
          freeTier: isFreeTier,
        },
      });
    },
    [isFreeTier, dispatch]
  );

  useEffect(
    function addClientIpEntry() {
      if (clientIp) {
        dispatch({
          type: 'update',
          payload: {
            allowedIPs: [fixCidr(clientIp)],
          },
        });
      }
    },
    [clientIp, dispatch]
  );

  useEffect(setDefaultScyllaVersion, [
    scyllaVersions,
    scyllaVersionId,
    dispatch,
  ]);
  // Cloud provider was changed - update the defaults
  useEffect(
    function updateCloudProviderDefaults() {
      let newState = {};

      if (regions?.length && instances?.length) {
        newState.regionId =
          urlRegionId && !isNaN(urlRegionId) && isAws(cloudProviderId)
            ? urlRegionId
            : defaultRegionId || regions[0].id;
        newState.instanceId = getDefaultInstance(
          instances,
          cloudProviderId
        )?.id;
      }

      if (scyllaVersions) {
        const defaultVersion = scyllaVersions.find(
          v => v.newCluster === 'ENABLED'
        );

        if (scyllaVersionId !== defaultVersion.id) {
          newState.scyllaVersionId = defaultVersion.id;
        }
      }

      if (!isEmpty(newState)) {
        dispatch({
          type: 'update',
          payload: newState,
        });
      }
    },
    [
      cloudProviderId,
      instances,
      regions,
      scyllaVersions,
      dispatch,
      defaultRegionId,
      urlRegionId,
      scyllaVersionId,
    ]
  );

  useEffect(
    function removeCidrOnBroadcastType() {
      if (broadcastType === BROADCAST_TYPE.PUBLIC) {
        dispatch({
          type: 'update',
          payload: {
            cidrBlock: null,
          },
        });
      }
    },
    [broadcastType, dispatch]
  );

  // cloud accounts /cloudProviderId change - update default accountCredentialId
  const { data: cloudAccounts } = useCloudAccounts();
  useEffect(
    function updateCloudCredentialDefaults() {
      if (cloudAccounts && cloudProviderId) {
        const accountCredentialId = getDefaultCredentialId(
          getActiveCloudAccounts(cloudAccounts),
          cloudProviderId
        );
        dispatch({
          type: 'update',
          payload: { accountCredentialId },
        });
      }
    },
    [cloudAccounts, cloudProviderId, dispatch]
  );

  useEffect(
    function updateCloudAccountDefaults() {
      if (!instances?.length) {
        return;
      }

      const newState = {
        instanceId: getDefaultInstance(instances, cloudProviderId)?.id,
      };

      dispatch({
        type: 'update',
        payload: newState,
      });
    },
    [dispatch, instances, cloudProviderId]
  );

  // alternator selected - set default isolation policy
  useEffect(
    function updateIsolationPolicy() {
      if (userApiInterface === USER_API_INTERFACE.ALTERNATOR) {
        dispatch({
          type: 'update',
          payload: {
            alternatorWriteIsolation: ALTERNATOR_ISOLATION_POLICY_NAMES.ONLY,
          },
        });
      } else {
        dispatch({
          type: 'update',
          payload: {
            alternatorWriteIsolation: null,
          },
        });
      }
    },
    [userApiInterface, dispatch]
  );
}

function useNewClusterData() {
  const NewClusterContext = useContext(NewClusterDataContext);
  const AddDCContext = useContext(AddDCDataContext);

  const context = NewClusterContext || AddDCContext;
  if (context === undefined) {
    throw new Error(
      'useNewClusterData must be used within a NewClusterDataProvider'
    );
  }

  const { dispatch } = context;

  const updateClusterData = useCallback(
    function updateClusterData(state) {
      dispatch({
        type: 'update',
        payload: state,
      });
    },
    [dispatch]
  );

  const resetClusterData = useCallback(
    function resetClusterData(state) {
      dispatch({
        type: 'reset',
        payload: state,
      });
    },
    [dispatch]
  );

  const validateClusterData = useCallback(() => {
    let error;
    error = validateIPsArray(context.state.allowedIPs);
    if (error) {
      return error;
    }
  }, [context.state.allowedIPs]);

  return {
    state: context.state,
    updateClusterData,
    validateClusterData,
    resetClusterData,
  };
}

export { NewClusterDataProvider, useNewClusterData };

export default NewClusterDataProvider;
