export function createFormDataReducer(defaultState) {
  return function formDataReducer(state, action) {
    switch (action.type) {
      case 'update': {
        return { ...state, ...action.payload };
      }
      case 'reset': {
        return { clusterName: '', ...defaultState };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  };
}
