import axios from 'axios';

import { logRequestErrors, rethrowNewError } from 'utils/error-handlers';

const defaultOptions = {
  interpolationPattern: /\{\{(\w+)\}\}/gi,
  inputMap: undefined,
  headersMap: undefined,
  withCredentials: false,
  parsers: [],
  enrichResult: false,
  requestInterceptors: [],
  responseInterceptors: [],
};

function invokePipes(interceptors = [], data, context) {
  let interceptorsArr;
  if (!Array.isArray(interceptors)) {
    interceptorsArr = [interceptors];
  } else {
    interceptorsArr = interceptors;
  }
  const originalData = Object.freeze(data);
  return interceptorsArr.reduce(
    (interData, interceptor) => interceptor(interData, context),
    originalData
  );
}

function createResource(method, apiUrl, options = {}) {
  const expandedOptions = { ...defaultOptions, ...options };

  const { withCredentials } = expandedOptions;
  const axiosInst = axios.create({
    withCredentials,
    method: method.toLowerCase(),
  });

  const { requestInterceptors, responseInterceptors } = expandedOptions;
  requestInterceptors.forEach(interceptor => {
    axiosInst.request.interceptors.use(
      ...(Array.isArray(interceptor) ? interceptor : [interceptor])
    );
  });
  responseInterceptors.forEach(interceptor => {
    axiosInst.request.interceptors.use(
      ...(Array.isArray(interceptor) ? interceptor : [interceptor])
    );
  });

  function getAxiosInstance() {
    return axiosInst;
  }

  function buildUrl(urlParams = {}) {
    const { interpolationPattern } = expandedOptions;
    return apiUrl.replace(interpolationPattern, (match, p1) => {
      if (Object.prototype.hasOwnProperty.call(urlParams, p1)) {
        return encodeURIComponent(urlParams[p1]);
      }
      return match;
    });
  }

  function getTransformedDataFromMap(payload, map) {
    return Object.keys(map).reduce(
      (data, key) => ({
        ...data,
        ...(payload.hasOwnProperty(key) && { [map[key]]: payload[key] }),
      }),
      {}
    );
  }

  async function call(payload = undefined) {
    const { inputMap, headersMap, queryParamsMap, parsers, enrichResult } =
      expandedOptions;

    const fullUrl = buildUrl(payload);
    const transformedPayload = inputMap
      ? getTransformedDataFromMap(payload, inputMap)
      : undefined;
    const headers = headersMap
      ? getTransformedDataFromMap(payload, headersMap)
      : undefined;

    const queryParams =
      queryParamsMap && getTransformedDataFromMap(payload, queryParamsMap);

    const axiosOptions = {};

    axiosOptions.url = queryParams
      ? `${fullUrl}?${new URLSearchParams(queryParams).toString()}`
      : fullUrl;
    if (axiosInst.defaults.method === 'get') {
      axiosOptions.params = transformedPayload;
    } else {
      axiosOptions.data = transformedPayload;
    }
    if (headers) {
      axiosOptions.headers = { ...axiosInst.defaults.headers, ...headers };
    }

    let responseBody;
    let isFailure;
    let status;
    try {
      const response = await axiosInst.request(axiosOptions);

      isFailure = false;
      ({ data: responseBody, status } = response);
    } catch (error) {
      isFailure = true;
      if (error.response) {
        ({ data: responseBody, status } = error.response);
      } else {
        logRequestErrors(error, fullUrl);
        // fatal error, throw it here
        if (enrichResult) {
          rethrowNewError(error, {
            method,
            fullUrl,
            isFatal: true,
            payload,
          });
        } else {
          rethrowNewError(error);
        }
      }
    }

    let result;
    const commonExtendedResult = {
      method,
      fullUrl,
      status,
      resultBody: responseBody,
      isFailure,
      payload,
    };
    try {
      result = invokePipes(parsers, responseBody, {
        isFailure,
        payload,
        status,
      });
    } catch (error) {
      // error in here are coming from completed requests
      const traceId = axiosOptions.headers['Trace-Id'];
      const thrownError = enrichResult
        ? { message: error.message, traceId, ...commonExtendedResult }
        : { message: error.message, traceId };
      logRequestErrors(error, fullUrl, traceId);
      throw thrownError;
    }
    return enrichResult ? { result, ...commonExtendedResult } : result;
  }

  return {
    getAxiosInstance,
    buildUrl,
    call,
  };
}

export function makeCreateResourceWithMiddlewares(...middlewares) {
  // later middleware in the list gets the result of the prior middleware in list
  return middlewares.reduce(
    (curCreateResource, md) => md(curCreateResource),
    createResource
  );
}
