export const PRICING_MODE = {
  ON_DEMAND: 'ON_DEMAND',
  RESERVED: 'RESERVED',
};

export const calcInputs = {
  reads: {
    name: 'Read ops/sec',
    testId: 'reads',
    min: 10000,
    max: 10000000,
    default: 500000,
    marks: ['10k ops/sec', '10M ops/sec'],
    scale: value => 10 ** value,
  },
  writes: {
    name: 'Write ops/sec',
    testId: 'writes',
    min: 10000,
    max: 10000000,
    default: 100000,
    marks: ['10k ops/sec', '10M ops/sec'],
    scale: value => 10 ** value,
  },
  itemSize: {
    name: 'Average item size (KB)',
    testId: 'item-size',
    min: 1,
    max: 20,
    default: 1,
    marks: ['1 KB', '20 KB'],
  },
  storage: {
    name: 'Storage set size (TB)',
    testId: 'storage',
    min: 1,
    max: 500,
    default: 1,
    marks: ['1 TB', '500 TB'],
  },
  cloudProvider: {
    name: 'Cloud Provider',
    default: 'AWS',
    skipInTheInputsPanel: true,
  },
  pricing: {
    name: 'Pricing',
    default: PRICING_MODE.ON_DEMAND,
    skipInTheInputsPanel: true,
  },
};

export const calcInternalInputs = {
  awsDataTransferPrice: {
    name: 'AWS Data Transfer Price',
    default: 0.01,
    internalParam: true,
  },
  throughputFactor: {
    name: 'Data Throughput Avg. Factor',
    default: 0.33,
    internalParam: true,
  },
  compactionOverhead: {
    name: 'Compaction Overhead',
    default: 1.4,
    internalParam: true,
  },
  performanceFactorA: {
    name: 'Performance Factor (A)',
    default: 4.81,
    internalParam: true,
  },
  performanceFactorB: {
    name: 'Performance Factor (B)',
    default: 3.764,
    internalParam: true,
  },
  performanceDegradationReads: {
    name: 'Performance Degradation due to compactions (Reads)',
    default: 0.8,
    internalParam: true,
  },
  performanceDegradationWrites: {
    name: 'Performance Degradation due to compactions (Writes)',
    default: 0.5,
    internalParam: true,
  },
  ramToDataRatio: {
    name: 'RAM to Data ratio',
    default: 75,
    internalParam: true,
  },
  ramToDiskRatio: {
    name: 'RAM to Disk ratio',
    default: 100,
    internalParam: true,
  },
  bestConfigSelectionThreshold: {
    name: 'Best Config Selection Threshold Multiplier',
    default: 1.2,
    step: 0.1,
    internalParam: true,
  },
};
