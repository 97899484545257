import { useQueryRequest } from 'hooks/fetch';

import { useDefaultAccountId } from './account';

const apiUrl = process.env.apiUrl;
export const clusterMaintenanceWindowsKey = (accountId, clusterId) =>
  accountId &&
  clusterId &&
  `${apiUrl}/account/${accountId}/cluster/${clusterId}/maintenance`;

export const allClustersMaintenanceWindowsKey = accountId =>
  accountId && `${apiUrl}/account/${accountId}/maintenance`;

export function useClusterMaintenanceWindows(clusterId) {
  const accountId = useDefaultAccountId();

  const key = clusterMaintenanceWindowsKey(accountId, clusterId);

  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useClusterMaintenanceWindowsInfo() {
  const accountId = useDefaultAccountId();

  const key =
    accountId && `${apiUrl}/account/${accountId}/cluster/maintenance/info`;

  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useMaintenanceWindowsForAllClusters() {
  const accountId = useDefaultAccountId();

  const key = accountId && allClustersMaintenanceWindowsKey(accountId);

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
  });

  return {
    maintenanceWindows: data,
    ...rest,
  };
}
