import { format, addMilliseconds } from 'date-fns';
import { RRule } from 'rrule';

import { DEAL_TYPES } from 'services/constants';
import { isDevelopmentInstance } from 'services/instance';
export const APPLY_TO_ALL = 'ALL';
export const APPLY_TO_NOT_SET = 'NOT_SET';

export async function handleMultipleMaintenanceRequests(requests) {
  const results = await Promise.allSettled(requests);
  const failures = results.filter(result => result.status !== 'fulfilled');
  if (failures.length) {
    throw failures[0].reason;
  }
  return results;
}

export function determineClusterIdsToApplyMaintenanceTo(
  applyTo,
  maintenanceWindowsGrouped,
  currentClusterId
) {
  let applyToClusters;
  if (applyTo === 'ALL') {
    applyToClusters = maintenanceWindowsGrouped.filter(
      perCluster => perCluster.clusterId !== currentClusterId
    );
  }
  if (applyTo === 'NOT_SET') {
    applyToClusters = maintenanceWindowsGrouped.filter(
      perCluster => perCluster.mWindows.length === 0
    );
  }

  return applyToClusters.map(perCluster => perCluster.clusterId);
}

export function rruleToWindow(rrule, duration) {
  const rule = RRule.fromString(rrule);
  const next = rule.after(new Date());
  const day = format(next, 'EEEE');
  const startHour = format(next, 'H:mm');
  const endHour = format(addMilliseconds(next, duration), 'H:mm');
  const hours = `${startHour} - ${endHour}`;

  return { day, hours, rrule, duration };
}

export function areRRulesSame(mWindow1, mWindow2) {
  return (
    rruleToWindow(mWindow1.rrule, mWindow1.duration).day ===
      rruleToWindow(mWindow2.rrule, mWindow2.duration).day &&
    rruleToWindow(mWindow1.rrule, mWindow1.duration).hours ===
      rruleToWindow(mWindow2.rrule, mWindow2.duration).hours &&
    rruleToWindow(mWindow1.rrule, mWindow1.duration).duration ===
      rruleToWindow(mWindow2.rrule, mWindow2.duration).duration
  );
}

export function byWeekDays(dayA, dayB) {
  const weekDaysAsc = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  return weekDaysAsc[dayA] - weekDaysAsc[dayB];
}

const startHour = h => Number(h.split(':')[0]);

export function byHours(hoursA, hoursB) {
  return startHour(hoursA) - startHour(hoursB);
}

export function byWindowTime(windowA, windowB) {
  return (
    byWeekDays(windowA.day, windowB.day) ||
    byHours(windowA.hours, windowB.hours)
  );
}

export function shouldClusterHaveMaintenance(cluster) {
  const freeTierDeal = cluster?.deals?.find(
    deal => deal.type === DEAL_TYPES.FREE_TIER
  );

  return !freeTierDeal && !isDevelopmentInstance(cluster?.instance);
}
