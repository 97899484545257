import CrossCloudRequest from './CrossCloudRequest';
import DeleteCloudAccount from './DeleteCloudAccount';
import RestartCloudAccountWizard from './RestartCloudAccount';
import AddDataCenterSuccess from './add-dc-success';
import AddTgwConnectionModal from './add-tgw-connection';
import ApplyMaintenance from './apply-maintenance';
import ClusterCtnEncryptionModeModal from './cluster-ctn-encryption';
import ClusterLocked from './cluster-locked';
import ExtractClusterMetrics from './cluster-metrics';
import ExtractPromProxyConfigModal from './cluster-metrics/extract';
import DeleteCluster from './delete-cluster';
import DeleteSecurityKey from './delete-security-key';
import DisableClusterMetrics from './disable-cluster-metrics';
import JumpStart from './jump-start';
import ClusterMaintenanceWindows from './maintenance-windows';
import RenameClusterModal from './rename-cluster';
import ResizeCluster from './resize-cluster';
import SetPayment from './set-payment';
import VPCSettingUpFailed from './vpc-setting-up-failed';

export const MODAL_ANALYTICS_TAGS = {
  [DeleteCluster.name]: 'DELETE',
  [ResizeCluster.name]: 'RESIZE',
  [AddDataCenterSuccess.name]: 'ADD_DC_SUCCESS',
  [SetPayment.name]: 'SET_PAYMENT',
  [VPCSettingUpFailed.name]: 'VPC_SETTING_UP_FAILED',
  [ExtractClusterMetrics.name]: 'METRICS',
  [ExtractPromProxyConfigModal.name]: 'DOWNLOAD_METRICS_CONFIG',
  [CrossCloudRequest.name]: 'CREDENTIALS_REQUEST',
  [DisableClusterMetrics.name]: 'DISABLE_METRICS',
  [JumpStart.name]: 'JUMP_START',
  [RestartCloudAccountWizard.name]: 'RESTART_CLOUD_ACCOUNT_WIZARD',
  [DeleteCloudAccount.name]: 'DELETE_CLOUD_ACCOUNT',
  [ClusterMaintenanceWindows.name]: 'MAINTENANCE',
  [ApplyMaintenance.name]: 'APPLY_MAINTENANCE_TO_ALL',
  [ClusterLocked.name]: 'CLUSTER_LOCKED',
  [AddTgwConnectionModal.name]: 'ADD_TGW_CONNECTION',
  [ClusterCtnEncryptionModeModal.name]: 'CTN_ENCRYPTION_MODE',
  [RenameClusterModal.name]: 'RENAME_CLUSTER',
};

export {
  AddDataCenterSuccess,
  ApplyMaintenance,
  ExtractClusterMetrics,
  ExtractPromProxyConfigModal,
  CrossCloudRequest,
  DeleteCloudAccount,
  DeleteCluster,
  DisableClusterMetrics,
  JumpStart,
  ClusterMaintenanceWindows,
  ResizeCluster,
  RestartCloudAccountWizard,
  SetPayment,
  VPCSettingUpFailed,
  ClusterLocked,
  AddTgwConnectionModal,
  DeleteSecurityKey,
  ClusterCtnEncryptionModeModal,
  RenameClusterModal,
};
