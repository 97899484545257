import * as TYPES from './types';

const {
  FIRSTNAME,
  LASTNAME,
  EMAIL,
  PHONE,
  PASSWORD,
  PASSCODE,
  PASSCODE_ARRAY,
  TERMS_CONDITIONS,
  COMPANYNAME,
  ADDRESS,
  CITY,
  COUNTRY,
  STATE,
  POSTALCODE,
  NUMBER,
  MONTH,
  YEAR,
  CVV,
  CLUSTERNAME,
  IP,
  ALLOWED_IPS,
  AWS_ACCOUNT_ID,
  AWS_ARN,
  AWS_POLICY_ARN,
  AWS_ROLE_ARN,
  VPC_ID,
  VPC_NETWORK,
  VPC_REGION,
  CIDR,
  DATA_CENTER,
  GCP_PROJECT_ID,
  GCP_NETWORK_NAME,
} = TYPES;

/*
    Errors translations
*/

const translations = {
  [FIRSTNAME]: {
    BLANK: 'Please enter your first name',
    INVALID_FORMAT: 'Please enter a valid first name',
    LENGTH_LONG: 'Maximum characters limit exceeded',
  },
  [LASTNAME]: {
    BLANK: 'Please enter your last name',
    INVALID_FORMAT: 'Please enter a valid last name',
    LENGTH_LONG: 'Maximum characters limit exceeded',
  },
  [EMAIL]: {
    BLANK: 'Please enter your email',
    INVALID_FORMAT: 'Please enter a valid email',
  },
  [PHONE]: {
    BLANK: 'Please enter your phone number',
    INVALID_FORMAT: 'Please enter a valid phone number',
  },
  [PASSWORD]: {
    BLANK: 'Please enter a password',
    BLANK_USER: 'Please enter your password',
    LENGTH_SHORT: 'Password should have at least 8 characters',
    LENGTH_LONG: 'The given password is too long',
    NO_NUMBER: 'Password should have at least one number',
    NO_LETTER: 'Password should have at least one letter',
    NO_SPECIAL_CHAR: 'Password should have at least one special character',
    NOT_MATCHED: 'The given passwords do not match',
  },
  [PASSCODE]: {
    BLANK: 'Please enter a verification code',
    INVALID_FORMAT: 'Please enter a valid, 6-digit verification code',
  },
  [PASSCODE_ARRAY]: {
    INVALID_FORMAT: 'Please enter a valid 6-digit passcode',
  },
  [TERMS_CONDITIONS]: {
    BLANK: 'Please agree to the Terms of Service',
  },
  [COMPANYNAME]: {
    BLANK: 'Please enter your company name',
    LENGTH_LONG: 'Maximum characters limit exceeded',
    INVALID_FORMAT: 'Please enter a valid company name',
  },
  [ADDRESS]: {
    BLANK: 'Please enter your street and number',
    INVALID_FORMAT: 'Please enter a valid address',
  },
  [CITY]: {
    BLANK: 'Please enter your city',
    INVALID_FORMAT: 'Please enter a valid city',
  },
  [STATE]: {
    BLANK: 'Please enter your state',
  },
  [POSTALCODE]: {
    BLANK: 'Please enter your zip code',
    INVALID_FORMAT: 'Please enter a valid zip code',
  },
  [COUNTRY]: {
    BLANK: 'Please enter your country',
  },
  [NUMBER]: {
    BLANK: 'Please enter a valid credit card number',
  },
  [MONTH]: {
    BLANK: 'Please enter a valid month of expiration for your credit card',
  },
  [YEAR]: {
    BLANK: 'Please enter a valid year of expiration for your credit card',
  },
  [CVV]: {
    BLANK: 'Please enter a valid security code for your credit card',
  },
  [CLUSTERNAME]: {
    BLANK: 'Please enter a name for your cluster',
    INVALID_FORMAT:
      'Name should be 1-30 characters, start and end with an alphanumeric character',
    LENGTH_LONG: 'Maximum characters limit exceeded',
  },
  [IP]: {
    BLANK: 'Please enter an IP address',
    INVALID_FORMAT: 'Please enter a valid IP address',
  },
  [ALLOWED_IPS]: {
    INVALID_LENGTH: 'Please enter at least one IP address',
    ALREADY_EXIST: 'This IP address is already in the list',
    MAX_AMOUNT: 'Allowed IPs list has reached to maximum',
  },
  [CIDR]: {
    BLANK: 'Please enter a CIDR value',
    INVALID_FORMAT: 'Please enter a valid CIDR value',
    OVERLAPPING_ADDRESS_DC:
      'CIDR block is overlapping with an existing Data Center, please enter different value',
    OVERLAPPING_ADDRESS_VPC:
      'CIDR block is overlapping with an existing VPC peering, please enter different value',
  },
  [DATA_CENTER]: {
    BLANK: 'Please select a data center',
  },
  [AWS_ACCOUNT_ID]: {
    BLANK: 'Please enter an AWS account ID',
    INVALID_FORMAT: 'Please enter a valid AWS account ID (12 digit number)',
  },
  [AWS_ARN]: {
    BLANK: 'Please enter an AWS ARN',
    INVALID_FORMAT:
      'Please enter a valid AWS ARN (arn:partition:service:region:account-id:resource)',
  },
  [AWS_POLICY_ARN]: {
    BLANK: 'Please enter an AWS policy ARN',
    INVALID_FORMAT:
      'Please enter a valid AWS policy ARN (arn:aws:iam:[region]:account-id:policy)',
    MATCH_AWS_ID: "The ARN's account-id must match the provided AWS account-id",
  },
  [AWS_ROLE_ARN]: {
    BLANK: 'Please enter an AWS role ARN',
    INVALID_FORMAT:
      'Please enter a valid AWS role ARN (arn:aws:iam:[region]:account-id:role)',
    MATCH_AWS_ID: "The ARN's account-id must match the provided AWS account-id",
  },
  [VPC_ID]: {
    BLANK: 'Please enter a VPC ID',
    INVALID_FORMAT: 'Please enter a valid VPC ID (start with `vpc-`)',
  },
  [VPC_NETWORK]: {
    BLANK: 'Please enter a VPC network',
    INVALID_FORMAT: 'Please enter a valid CIDR value',
    INTERSECT_CLUSTER_CIDR: "Must not intersect with cluster's CIDR",
  },
  [VPC_REGION]: {
    BLANK: 'Please enter a VPC region',
  },
  [GCP_PROJECT_ID]: {
    BLANK: 'Please enter your GCP project Id',
    INVALID_FORMAT: 'Please enter a valid project Id',
  },
  [GCP_NETWORK_NAME]: {
    BLANK: 'Please enter your GCP network name',
    INVALID_FORMAT: 'Please enter a valid network name',
  },
};

export { TYPES };

export default translations;
