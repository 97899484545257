import PropTypes from 'prop-types';

import ScyllaHorizontalBlack from 'assets/icons/logo/ScyllaHorizontalBlack.svg?react';
import ScyllaHorizontalDefault from 'assets/icons/logo/ScyllaHorizontalDefault.svg?react';
import ScyllaHorizontalWhite from 'assets/icons/logo/ScyllaHorizontalWhite.svg?react';
import ScyllaMascotBlack from 'assets/icons/logo/ScyllaMascotBlack.svg?react';
import ScyllaMascotDefault from 'assets/icons/logo/ScyllaMascotDefault.svg?react';
import ScyllaMascotWhite from 'assets/icons/logo/ScyllaMascotWhite.svg?react';
import ScyllaVerticalBlack from 'assets/icons/logo/ScyllaVerticalBlack.svg?react';
import ScyllaVerticalDefault from 'assets/icons/logo/ScyllaVerticalDefault.svg?react';
import ScyllaVerticalWhite from 'assets/icons/logo/ScyllaVerticalWhite.svg?react';

function Logo({
  color = 'default',
  variant = 'mascot',
  className,
  style,
  alt,
}) {
  const mascotIcons = {
    default: ScyllaMascotDefault,
    black: ScyllaMascotBlack,
    white: ScyllaMascotWhite,
  };

  const verticalIcons = {
    default: ScyllaVerticalDefault,
    black: ScyllaVerticalBlack,
    white: ScyllaVerticalWhite,
  };

  const horizontalIcons = {
    default: ScyllaHorizontalDefault,
    black: ScyllaHorizontalBlack,
    white: ScyllaHorizontalWhite,
  };

  const icons = {
    mascot: mascotIcons,
    vertical: verticalIcons,
    horizontal: horizontalIcons,
  };

  const Icon = icons[variant][color];

  return <Icon className={className} style={{ ...style }} alt={alt} />;
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['mascot', 'vertical', 'horizontal']),
  color: PropTypes.oneOf(['default', 'white', 'black']),
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
};

export default Logo;
