import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import LoadingSign from 'assets/images/loading-sign.svg?react';
import withStyles from 'components/withStylesAdapter';

export const LOCKED_CLUSTER_ACTIONS = {
  RESIZE: 'RESIZE',
  ADD_CONNECTION: 'ADD_CONNECTION',
  RENAME_CLUSTER: 'RENAME_CLUSTER',
};

export const LOCKED_ACTIONS_TEXT = {
  [LOCKED_CLUSTER_ACTIONS.RESIZE]: {
    header: 'Resize Cluster',
    description: 'resize your cluster',
  },
  [LOCKED_CLUSTER_ACTIONS.ADD_CONNECTION]: {
    header: 'Add Transit Gateway Connection',
    description: 'add new connection',
  },
  [LOCKED_CLUSTER_ACTIONS.RENAME_CLUSTER]: {
    header: 'Rename Cluster',
    description: 'rename your cluster',
  },
};

const withJss = withStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(32),
  },
  message: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.colors.baliHai,
  },
}));

function ClusterLockedContent({ classes, action }) {
  return (
    <Box className={classes.content}>
      <LoadingSign />
      <Typography
        variant="h6"
        className={classes.header}
        data-testid="otherRequestTextHeader"
      >
        Other Request In Progress
      </Typography>
      <Typography
        variant="body2"
        className={classes.message}
        data-testid="resizeTextMessage"
      >
        Another request for this cluster is currently in progress. Once
        completed, you’ll be able to {LOCKED_ACTIONS_TEXT[action].description}.
      </Typography>
    </Box>
  );
}

ClusterLockedContent.propTypes = {
  params: PropTypes.shape({
    action: PropTypes.oneOf(Object.values(LOCKED_CLUSTER_ACTIONS)).isRequired,
  }),
  classes: PropTypes.shape({}),
};

export default withJss(ClusterLockedContent);
