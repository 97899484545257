import { omit } from 'lodash/fp';
import { useForm, useFormContext } from 'react-hook-form';

export const FORM_DEFAULT_CONFIG = {
  mode: 'onChange',
};

// form hook optimized for material ui component bindings

export const useMuiFormContext = (customRegisterOptions = {}) => {
  const originalContext = useFormContext();

  const customRegister = createMuiRegister(originalContext, {
    ...customRegisterOptions,
  });

  return {
    ...originalContext,
    muiRegister: customRegister,
  };
};

export const useMuiForm = (
  formSettings = FORM_DEFAULT_CONFIG,
  customRegisterOptions = {}
) => {
  const originalForm = useForm(formSettings);

  const customRegister = createMuiRegister(originalForm, {
    ...customRegisterOptions,
  });

  return {
    ...originalForm,
    muiRegister: customRegister,
  };
};

const allowedRegisterUpdateEvt = ['change', 'blur'];

export const createMuiRegister = (formContext, customUpdateOptions) => {
  const { updateOn = 'blur', updateFn } = customUpdateOptions;

  const customRegister = fullFieldOptions => {
    const { name, defaultValue, useErrorText, helperText } = fullFieldOptions;
    if (updateOn && !allowedRegisterUpdateEvt.includes(updateOn)) {
      console.error(
        `Invalid updateOn parameter, only ${allowedRegisterUpdateEvt.join(
          ', '
        )} are allowed`
      );
    }

    const defaultUpdate = updateFn
      ? updateOn === 'change'
        ? { onChange: updateFn.bind(null, name) }
        : { onBlur: updateFn.bind(null, name) }
      : {};

    const baseRegisterReturn = formContext.register(name, {
      ...defaultUpdate,
      ...omit(['name', 'defaultValue', 'useErrorText'], fullFieldOptions),
    });

    const hasError = Boolean(formContext.formState.errors[name]);

    return {
      ...baseRegisterReturn,
      ...(defaultValue !== undefined ? { defaultValue } : {}),
      ref: undefined,
      inputRef: baseRegisterReturn.ref,
      ...(useErrorText
        ? {
            ...(hasError
              ? { helperText: formContext.formState.errors[name]?.message }
              : {}),
            error: hasError,
          }
        : { helperText: helperText || '' }),
    };
  };

  return customRegister;
};
