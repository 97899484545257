// USER
export const FIRSTNAME = 'FIRSTNAME';
export const LASTNAME = 'LASTNAME';
export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';
export const PASSWORD = 'PASSWORD';
export const PASSCODE = 'PASSCODE';
export const PASSCODE_ARRAY = 'PASSCODE_ARRAY';
export const TERMS_CONDITIONS = 'TERMS_CONDITIONS';

// CLUSTER
export const CLUSTERNAME = 'CLUSTERNAME';
export const IP = 'IP';
export const ALLOWED_IPS = 'ALLOWED_IPS';
export const CIDR = 'CIDR';
export const DATA_CENTER = 'DATA_CENTER';

// BILLING
export const COMPANYNAME = 'COMPANYNAME';
export const ADDRESS = 'ADDRESS';
export const CITY = 'CITY';
export const STATE = 'STATE';
export const POSTALCODE = 'POSTALCODE';
export const COUNTRY = 'COUNTRY';
export const NUMBER = 'NUMBER';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const CVV = 'CVV';

// VPC Peering
export const AWS_ACCOUNT_ID = 'AWS_ACCOUNT_ID';
export const VPC_ID = 'VPC_ID';
export const VPC_NETWORK = 'VPC_NETWORK';
export const VPC_REGION = 'VPC_REGION';

// Cloud Account
export const AWS_ARN = 'AWS_ARN';
export const AWS_POLICY_ARN = 'AWS_POLICY_ARN';
export const AWS_ROLE_ARN = 'AWS_ROLE_ARN';

//  GCP
export const GCP_PROJECT_ID = 'GCP_PROJECT_ID';
export const GCP_NETWORK_NAME = 'GCP_NETWORK_NAME';
