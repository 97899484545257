import { registerUserMetadata } from 'services/resources/user';

function isMetadataPresent(metadata) {
  return Object.values(metadata).some(m => Boolean(m));
}

export function gatherSignUpMarketingMetadata() {
  const queryParams = new URLSearchParams(document.location.search);

  return {
    utm_medium: queryParams.get('utm_medium'),
    utm_campaign: queryParams.get('utm_campaign'),
    utm_source: queryParams.get('utm_source'),
  };
}

export function sendSignUpMarketingMetadata(userId) {
  const metadata = gatherSignUpMarketingMetadata();

  if (isMetadataPresent(metadata)) {
    const payload = {
      userId,
      provider: 'frontegg',
      metadata,
    };

    registerUserMetadata.call(payload);
  }
}
