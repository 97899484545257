import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Grid, Typography, Zoom } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import ScyllaMonster from 'assets/icons/scylla/scyllamonster.svg?react';
import { useHasFeature } from 'components/providers/features/with-features';
import withStyles from 'components/withStylesAdapter';
import { useAuthentication } from 'hooks/authentication';
import { useScyllaBanner } from 'hooks/banners';

const withJss = withStyles(theme => ({
  wrapper: {
    position: 'fixed',
    bottom: theme.typography.pxToRem(10),
    right: theme.typography.pxToRem(10),
    minHeight: theme.typography.pxToRem(200),
    width: theme.typography.pxToRem(500),
    background: `linear-gradient(to right, ${theme.colors.deepDarkMarino}, ${theme.colors.sanMarino})`,
    color: theme.colors.white,
    padding: theme.spacing(1),
  },
  head: {
    height: '5px',
  },
  body: {
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',

    '&:hover': {
      transform: 'scale(1.05)',
      color: theme.colors.mystic,
    },
  },
  close: {
    zIndex: 1,
    color: theme.colors.baliHai,

    '&:hover': {
      color: theme.colors.white,
    },
  },
  content: {
    padding: '0 0.5rem 0 27%',
  },
  title: {
    color: theme.colors.white,
    fontWeight: 'bold',
    marginTop: '0.75rem',
    fontSize: theme.typography.pxToRem(26),
  },
  text: {
    color: theme.colors.periwinkleGray,
    minHeight: theme.typography.pxToRem(100),
  },
  scyllaImage: {
    position: 'absolute',
    bottom: '0',
    left: '-8%',

    width: '35%',
    transform: 'rotate(20deg)',
    opacity: '0.7',
  },
}));

function goToLink(link) {
  window.open(link, '_blank');
}

function InfoBanner({ classes = {} }) {
  const { isAuthenticated } = useAuthentication();
  const { data, isLoading, dismiss } = useScyllaBanner();
  const isBannerEnabled = useHasFeature('BANNER');

  const [isVisible, setIsVisible] = useState(true);

  const isLoaded = !isLoading && data;

  const closeBanner = useCallback(
    function closeBannerFn(event) {
      event.stopPropagation();
      dismiss(data.title, data.date);
      setIsVisible(false);
    },
    [data, dismiss, setIsVisible]
  );

  if (!isBannerEnabled || !isAuthenticated) {
    return <></>;
  }

  return (
    <Zoom
      in={isLoaded && isVisible}
      style={{ transitionDelay: '1000ms', transitionDuration: '1000ms' }}
    >
      <Card className={classes.wrapper}>
        <CardContent className={classes.content}>
          <Grid container direction={'column'}>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="flex-start"
              className={classes.head}
            >
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={closeBanner}
              >
                <CloseIcon
                  fontSize="small"
                  id="closeBanner"
                  data-testid="closeBanner"
                />
              </IconButton>
            </Grid>
            <Grid
              item
              container
              direction={'column'}
              className={classes.body}
              onClick={() => goToLink(data?.link)}
            >
              <Typography
                variant="h4"
                classes={{
                  root: classes.title,
                }}
              >
                {data?.title}
              </Typography>
              <Typography
                classes={{
                  root: classes.text,
                }}
                dangerouslySetInnerHTML={{ __html: data?.text }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <ScyllaMonster className={classes.scyllaImage} />
      </Card>
    </Zoom>
  );
}

InfoBanner.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withJss(InfoBanner);
