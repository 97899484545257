import { Box } from '@mui/material';

import { MainModalActionButton } from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  successButton: {
    maxWidth: '180px',
  },
}));

function ResizeClusterModalActions({
  resizeCluster,
  hideModal,
  isActionDisabled,
  resizeSuccessful,
  isResizeInProgress,
  isResizeRequestLoading,
  classes,
}) {
  if (isResizeInProgress) {
    return (
      <Box className={classes.centeredButtonsContainer}>
        <MainModalActionButton
          id="closeModalBtn"
          type={MainModalActionButton.Types.MAJOR}
          onClick={hideModal}
          data-testid="resizeActionCancelButton"
        >
          I UNDERSTAND
        </MainModalActionButton>
      </Box>
    );
  }

  if (resizeSuccessful)
    return (
      <MainModalActionButton
        id="goBackBtn"
        type={MainModalActionButton.Types.MAJOR}
        onClick={hideModal}
        fullWidth
        className={classes.successButton}
        data-testid="resizeActionCancelButton"
      >
        BACK TO CLUSTER
      </MainModalActionButton>
    );

  return (
    <div className={classes.buttonsContainer}>
      <MainModalActionButton
        id="resizeActionSubmitButton"
        type={MainModalActionButton.Types.MAJOR}
        onClick={resizeCluster}
        disabled={isActionDisabled}
        isLoading={isResizeRequestLoading}
        data-testid="resizeActionSubmitButton"
      >
        RESIZE
      </MainModalActionButton>
      <MainModalActionButton
        id="resizeClusterCancelBtn"
        data-testid="resizeActionCancelButton"
        type={MainModalActionButton.Types.MINOR}
        onClick={hideModal}
      >
        CANCEL
      </MainModalActionButton>
    </div>
  );
}

export default withJss(ResizeClusterModalActions);
