export const contentVPaddingInt = 30;
export const contentHPaddingInt = 32;
export const contentVPadding = `${contentVPaddingInt}px`;
export const contentHPadding = `${contentHPaddingInt}px`;
export const boxBorderRadius = '3px';
export const contentPadding = `${contentVPadding} ${contentHPadding}`;
export const contentBorderRadius = '8px';
export const headerHeight = '60px';
export const inputLabelFontSize = '15px';
export const inputFontSize = '1rem';
export const loadable = {
  monitor: `${contentVPaddingInt + 36}px ${contentHPadding} ${contentVPadding}`,
  connect: 18,
};
export const maxContentWidth = 1440;
