import { DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(() => ({
  root: {
    padding: '1rem 1.5rem',
  },
  newRoot: {
    padding: '0.5rem 1.5rem 0.5rem',
    position: 'relative',
    zIndex: '1',
  },
}));

function MainModalContent({ children, classes, newDesign = false }) {
  return (
    <div>
      <DialogContent
        data-testid="MainModalContent"
        className={newDesign ? classes.newRoot : classes.root}
      >
        {children}
      </DialogContent>
    </div>
  );
}

MainModalContent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({}),
  newDesign: PropTypes.bool,
};

export default withJss(MainModalContent);
