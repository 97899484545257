import BuildIcon from '@mui/icons-material/BuildOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import AlertWithTraceId from 'components/common/alert-with-trace-id';
import MainModal, {
  MainModalContent,
  MainModalHeader,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';
import { useDefaultAccountId } from 'hooks/account';
import { useAuthHeader } from 'hooks/authentication';
import { axiosWithTokenFetcher, useMutationRequest } from 'hooks/fetch';
import { extractFileFromResponse } from 'services/file-download';

const apiUrl = process.env.apiUrl;

const withJss = withStyles(theme => ({
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  link: theme.extensions.link,
  cloudIcon: {
    marginRight: theme.spacing(1),
  },
  downloadBtn: {
    whiteSpace: 'nowrap',
  },
}));

function useRequestPromProxyConfig(clusterId, onSuccess) {
  const authHeader = useAuthHeader();

  const accountId = useDefaultAccountId();
  const url =
    accountId &&
    clusterId &&
    `${apiUrl}/account/${accountId}/cluster/${clusterId}/promproxy/config`;

  const requester = useCallback(
    () =>
      axiosWithTokenFetcher(authHeader)(url, { fullResponse: true }).then(
        extractFileFromResponse
      ),
    [authHeader, url]
  );

  return useMutationRequest({ requester, onSuccess });
}

function ExtractPromProxyConfigModal({
  hideModal,
  classes,
  params: { clusterId },
}) {
  const theme = useTheme();
  const {
    request: configRequest,
    isLoading,
    error,
  } = useRequestPromProxyConfig(clusterId, hideModal);

  return (
    <MainModal onClose={hideModal} maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<BuildIcon fontSize="medium" />}
        helperIconColor={theme.colors.mineShaft}
      >
        Extract Cluster Metrics
      </MainModalHeader>
      <MainModalContent>
        <AlertWithTraceId error={error} id="extactClusterMetricsModalError" />
        <Typography paragraph>
          Download and use the Prometheus configuration YAML file to use an
          external Monitoring Stack.
        </Typography>
        <Typography paragraph>
          For more information see{' '}
          <a
            href={documentationLinks.cloudDocs.cloudSetup.promProxy}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="docsLink"
          >
            Scylla Cloud Docs
          </a>{' '}
        </Typography>
      </MainModalContent>

      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="downloadConfigBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={configRequest}
            className={classes.downloadBtn}
            isLoading={isLoading}
            fullWidth
          >
            <CloudDownloadOutlinedIcon className={classes.cloudIcon} />
            DOWNLOAD YAML
          </MainModalActionButton>
          <MainModalActionButton
            id="cancelModalBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

ExtractPromProxyConfigModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    clusterId: PropTypes.string,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(ExtractPromProxyConfigModal);
