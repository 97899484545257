import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

import MainModalActionButton from './action-button';
import MainModalActions from './actions';
import MainModalContent from './content';
import MainModalHeader from './header';

const withJss = withStyles(theme => ({
  paper: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
  },
}));

function MainModalComponent({ children, classes, open = true, ...props }) {
  return (
    <Dialog open={open} classes={classes} {...props}>
      {children}
    </Dialog>
  );
}

MainModalComponent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({}),
  open: PropTypes.bool,
};

export {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
};

export default withJss(MainModalComponent);
