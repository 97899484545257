import { useReducer, createContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FORM_DEFAULT_CONFIG } from 'components/pages/new-cluster/hooks/form';
import { createFormDataReducer } from 'components/pages/new-cluster/hooks/reducer';

const AddDCDataContext = createContext();

function AddDCDataProvider({ children, cluster }) {
  const {
    userApiInterface,
    clusterName,
    dc: {
      cloudProviderId,
      accountCloudProviderCredentialsId,
      instanceId,
      regionId,
      replicationFactor,
    },
  } = cluster;
  const defaults = {
    cloudProviderId,
    accountCredentialId: accountCloudProviderCredentialsId,
    instanceId,
    regionId,
    replicationFactor,
    numberOfNodes: replicationFactor,
    userApiInterface,
    clusterName,
  };

  const [state, dispatch] = useReducer(createFormDataReducer(defaults), {
    ...defaults,
  });

  const form = useForm(FORM_DEFAULT_CONFIG);

  const value = { state, dispatch };
  return (
    <AddDCDataContext.Provider value={value}>
      <FormProvider {...form}>{children}</FormProvider>
    </AddDCDataContext.Provider>
  );
}

export { AddDCDataContext, AddDCDataProvider };
