import { datadogLogs } from '@datadog/browser-logs';

const LOG_LEVELS = {
  ERROR: 'error',
  INFO: 'info',
  WARN: 'warn',
};

function shouldLog(level) {
  return process.env.DataDogLogLevels?.includes(level);
}

function logToConsole(level, message, prefix, extraData) {
  const formattedMessage = prefix ? `[${prefix}]: ${message}` : message;
  const logMethod = level !== LOG_LEVELS.INFO ? console[level] : console.log;

  extraData
    ? logMethod(formattedMessage, extraData)
    : logMethod(formattedMessage);
}

function logToDatadog({ level, prefix, message, extraData, error }) {
  const formattedMessage = prefix ? `[${prefix}]: ${message}` : message;
  const logData = {};

  if (prefix) {
    logData.prefix = prefix;
  }

  if (extraData) {
    Object.assign(logData, extraData);
  }

  const logParams = [formattedMessage, { logData }];

  if (error) {
    logParams.push(error);
  }

  datadogLogs.logger[level](...logParams);
}

const logger = {
  error: ({ ...params }) => {
    const { message, prefix, extraData, error } = params;
    if (shouldLog(LOG_LEVELS.ERROR)) {
      logToConsole(LOG_LEVELS.ERROR, message, prefix, extraData);
      logToDatadog({
        level: LOG_LEVELS.ERROR,
        message,
        prefix,
        extraData,
        error,
      });
    }
  },
  warn: ({ ...params }) => {
    const { message, prefix, extraData } = params;
    if (shouldLog(LOG_LEVELS.WARN)) {
      logToConsole(LOG_LEVELS.WARN, message, prefix, extraData);
      logToDatadog({ level: LOG_LEVELS.WARN, message, prefix, extraData });
    }
  },
  info: ({ ...params }) => {
    const { message, prefix, extraData } = params;
    if (shouldLog(LOG_LEVELS.INFO)) {
      logToConsole(LOG_LEVELS.INFO, message, prefix, extraData);
      logToDatadog({ level: LOG_LEVELS.INFO, message, prefix, extraData });
    }
  },
};

export default logger;
