export const mockRegions = {
  data: {
    regions: [
      {
        id: 1,
        externalId: 'us-east-1',
        cloudProviderId: 1,
        name: 'us-east-1',
        dcName: 'AWS_US_EAST_1',
        fullName: 'US East (N. Virginia)',
        continent: 'America',
        backupStorageGBCost: '0.023',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 2,
        externalId: 'us-west-1',
        cloudProviderId: 1,
        name: 'us-west-1',
        dcName: 'AWS_US_WEST_1',
        fullName: 'US West (N. California)',
        continent: 'America',
        backupStorageGBCost: '0.026',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 10,
        externalId: 'us-east-2',
        cloudProviderId: 1,
        name: 'us-east-2',
        dcName: 'AWS_US_EAST_2',
        fullName: 'US East (Ohio)',
        continent: 'America',
        backupStorageGBCost: '0.023',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 3,
        externalId: 'us-west-2',
        cloudProviderId: 1,
        name: 'us-west-2',
        dcName: 'AWS_US_WEST_2',
        fullName: 'US West (Oregon)',
        continent: 'America',
        backupStorageGBCost: '0.023',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 40,
        externalId: 'ca-central-1',
        cloudProviderId: 1,
        name: 'ca-central-1',
        dcName: 'AWS_CA_CENTRAL_1',
        fullName: 'Canada (Central)',
        continent: 'America',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 11,
        externalId: 'sa-east-1',
        cloudProviderId: 1,
        name: 'sa-east-1',
        dcName: 'AWS_SA_EAST_1',
        fullName: 'South America (Sao Paulo)',
        continent: 'America',
        backupStorageGBCost: '0.0405',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.138',
        trafficInternetOutGBCost: '0.15',
      },
      {
        id: 38,
        externalId: 'ap-east-1',
        cloudProviderId: 1,
        name: 'ap-east-1',
        dcName: 'AWS_AP_EAST_1',
        fullName: 'Asia Pacific (Hong Kong)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.09',
        trafficInternetOutGBCost: '0.12',
      },
      {
        id: 9,
        externalId: 'ap-south-1',
        cloudProviderId: 1,
        name: 'ap-south-1',
        dcName: 'AWS_AP_SOUTH_1',
        fullName: 'Asia Pacific (Mumbai)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.086',
        trafficInternetOutGBCost: '0.1093',
      },
      {
        id: 45,
        externalId: 'ap-northeast-3',
        cloudProviderId: 1,
        name: 'ap-northeast-3',
        dcName: 'AWS_AP_NORTHEAST_3',
        fullName: 'Asia Pacific (Osaka)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.09',
        trafficInternetOutGBCost: '0.114',
      },
      {
        id: 39,
        externalId: 'ap-northeast-2',
        cloudProviderId: 1,
        name: 'ap-northeast-2',
        dcName: 'AWS_AP_NORTHEAST_2',
        fullName: 'Asia Pacific (Seoul)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.08',
        trafficInternetOutGBCost: '0.126',
      },
      {
        id: 4,
        externalId: 'ap-southeast-1',
        cloudProviderId: 1,
        name: 'ap-southeast-1',
        dcName: 'AWS_AP_SOUTHEAST_1',
        fullName: 'Asia Pacific (Singapore)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.09',
        trafficInternetOutGBCost: '0.12',
      },
      {
        id: 8,
        externalId: 'ap-southeast-2',
        cloudProviderId: 1,
        name: 'ap-southeast-2',
        dcName: 'AWS_AP_SOUTHEAST_2',
        fullName: 'Asia Pacific (Sydney)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.14',
        trafficInternetOutGBCost: '0.114',
      },
      {
        id: 7,
        externalId: 'ap-northeast-1',
        cloudProviderId: 1,
        name: 'ap-northeast-1',
        dcName: 'AWS_AP_NORTHEAST_1',
        fullName: 'Asia Pacific (Tokyo)',
        continent: 'Asia',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.09',
        trafficInternetOutGBCost: '0.114',
      },
      {
        id: 36,
        externalId: 'eu-west-2',
        cloudProviderId: 1,
        name: 'eu-west-2',
        dcName: 'AWS_EU_WEST_2',
        fullName: 'Europe (London)',
        continent: 'Europe',
        backupStorageGBCost: '0.024',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 41,
        externalId: 'eu-south-1',
        cloudProviderId: 1,
        name: 'eu-south-1',
        dcName: 'AWS_EU_SOUTH_1',
        fullName: 'Europe (Milan)',
        continent: 'Europe',
        backupStorageGBCost: '0.024',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 43,
        externalId: 'eu-west-3',
        cloudProviderId: 1,
        name: 'eu-west-3',
        dcName: 'AWS_EU_WEST_3',
        fullName: 'Europe (Paris)',
        continent: 'Europe',
        backupStorageGBCost: '0.024',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 5,
        externalId: 'eu-west-1',
        cloudProviderId: 1,
        name: 'eu-west-1',
        dcName: 'AWS_EU_WEST_1',
        fullName: 'EU (Ireland)',
        continent: 'Europe',
        backupStorageGBCost: '0.023',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 6,
        externalId: 'eu-central-1',
        cloudProviderId: 1,
        name: 'eu-central-1',
        dcName: 'AWS_EU_CENTRAL_1',
        fullName: 'EU (Frankfurt)',
        continent: 'Europe',
        backupStorageGBCost: '0.0245',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 42,
        externalId: 'eu-north-1',
        cloudProviderId: 1,
        name: 'eu-north-1',
        dcName: 'AWS_EU_NORTH_1',
        fullName: 'Europe (Stockholm)',
        continent: 'Europe',
        backupStorageGBCost: '0.023',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.02',
        trafficInternetOutGBCost: '0.09',
      },
      {
        id: 37,
        externalId: 'af-south-1',
        cloudProviderId: 1,
        name: 'af-south-1',
        dcName: 'AWS_AF_SOUTH_1',
        fullName: 'Africa (Cape Town)',
        continent: 'Africa',
        backupStorageGBCost: '0.0274',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.147',
        trafficInternetOutGBCost: '0.154',
      },
      {
        id: 44,
        externalId: 'me-south-1',
        cloudProviderId: 1,
        name: 'me-south-1',
        dcName: 'AWS_ME_SOUTH_1',
        fullName: 'Middle East (Bahrain)',
        continent: 'Middle East',
        backupStorageGBCost: '0.025',
        trafficSameRegionInGBCost: '0.01',
        trafficSameRegionOutGBCost: '0.01',
        trafficCrossRegionOutGBCost: '0.1105',
        trafficInternetOutGBCost: '0.117',
      },
    ],
  },
};

export const mockCloudProviders = [
  {
    id: 1,
    name: 'AWS',
    rootAccountId: '123',
  },
  {
    id: 2,
    name: 'GCP',
    rootAccountId: 'abc',
  },
];

export const userAccount = {
  id: 1,
  name: 'ScyllaDefaultAccount',
  isBillingVerified: false,
  deals: [
    { type: 'FREE_TIER', providerId: 1, isEligible: true, total: 96, used: 0 },
  ],
};

export const mockRegionInstances = [
  {
    id: 45,
    externalId: 't3.micro',
    cloudProviderId: 1,
    memory: 1024,
    localDiskCount: 0,
    totalStorage: 5,
    cpuCount: 2,
    networkSpeed: 5120,
    externalStorageNetworkSpeed: 2085,
    costPerHour: '0',
    environment: 'DEVELOPMENT',
    licenseCostOnDemandPerHour: '0',
    subscriptionCostHourly: '0',
    instanceCostHourly: '0.0104',
    freeTierHours: 0,
  },
  {
    id: 1,
    externalId: 'i3.large',
    cloudProviderId: 1,
    memory: 15616,
    localDiskCount: 1,
    totalStorage: 486,
    cpuCount: 2,
    networkSpeed: 10240,
    externalStorageNetworkSpeed: 0,
    costPerHour: '0.327',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '0.171',
    subscriptionCostHourly: '0.23657265625',
    instanceCostHourly: '0.156',
    freeTierHours: 0,
  },
  {
    id: 2,
    externalId: 'i3.xlarge',
    cloudProviderId: 1,
    memory: 31232,
    localDiskCount: 1,
    totalStorage: 972,
    cpuCount: 4,
    networkSpeed: 10240,
    externalStorageNetworkSpeed: 0,
    costPerHour: '0.654',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '0.342',
    subscriptionCostHourly: '0.4731453125',
    instanceCostHourly: '0.312',
    freeTierHours: 0,
  },
  {
    id: 3,
    externalId: 'i3.2xlarge',
    cloudProviderId: 1,
    memory: 62464,
    localDiskCount: 1,
    totalStorage: 1945,
    cpuCount: 8,
    networkSpeed: 10240,
    externalStorageNetworkSpeed: 0,
    costPerHour: '1.309',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '0.685',
    subscriptionCostHourly: '0.946383984375',
    instanceCostHourly: '0.624',
    freeTierHours: 3,
  },
  {
    id: 4,
    externalId: 'i3.4xlarge',
    cloudProviderId: 1,
    memory: 124928,
    localDiskCount: 2,
    totalStorage: 3891,
    cpuCount: 16,
    networkSpeed: 10240,
    externalStorageNetworkSpeed: 0,
    costPerHour: '2.618',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '1.37',
    subscriptionCostHourly: '1.892861328125',
    instanceCostHourly: '1.248',
    freeTierHours: 0,
  },
  {
    id: 5,
    externalId: 'i3.8xlarge',
    cloudProviderId: 1,
    memory: 249856,
    localDiskCount: 4,
    totalStorage: 7782,
    cpuCount: 32,
    networkSpeed: 10240,
    externalStorageNetworkSpeed: 0,
    costPerHour: '5.236',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '2.74',
    subscriptionCostHourly: '3.78572265625',
    instanceCostHourly: '2.496',
    freeTierHours: 0,
  },
  {
    id: 6,
    externalId: 'i3.16xlarge',
    cloudProviderId: 1,
    memory: 499712,
    localDiskCount: 8,
    totalStorage: 15564,
    cpuCount: 64,
    networkSpeed: 25600,
    externalStorageNetworkSpeed: 0,
    costPerHour: '10.471',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '5.479',
    subscriptionCostHourly: '7.5714453125',
    instanceCostHourly: '4.992',
    freeTierHours: 0,
  },
  {
    id: 8,
    externalId: 'i3en.large',
    cloudProviderId: 1,
    memory: 16384,
    localDiskCount: 1,
    totalStorage: 1250,
    cpuCount: 2,
    networkSpeed: 25600,
    externalStorageNetworkSpeed: 0,
    costPerHour: '0.568',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '0.342',
    subscriptionCostHourly: '0.30789921875',
    instanceCostHourly: '0.226',
    freeTierHours: 0,
  },
  {
    id: 9,
    externalId: 'i3en.xlarge',
    cloudProviderId: 1,
    memory: 32768,
    localDiskCount: 1,
    totalStorage: 2500,
    cpuCount: 4,
    networkSpeed: 25600,
    externalStorageNetworkSpeed: 0,
    costPerHour: '1.137',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '0.685',
    subscriptionCostHourly: '0.6157984375',
    instanceCostHourly: '0.452',
    freeTierHours: 0,
  },
  {
    id: 10,
    externalId: 'i3en.2xlarge',
    cloudProviderId: 1,
    memory: 65536,
    localDiskCount: 2,
    totalStorage: 5000,
    cpuCount: 8,
    networkSpeed: 25600,
    externalStorageNetworkSpeed: 0,
    costPerHour: '2.274',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '1.37',
    subscriptionCostHourly: '1.231596875',
    instanceCostHourly: '0.904',
    freeTierHours: 0,
  },
  {
    id: 11,
    externalId: 'i3en.3xlarge',
    cloudProviderId: 1,
    memory: 98304,
    localDiskCount: 1,
    totalStorage: 7500,
    cpuCount: 12,
    networkSpeed: 25600,
    externalStorageNetworkSpeed: 0,
    costPerHour: '3.411',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '2.055',
    subscriptionCostHourly: '1.8473953125',
    instanceCostHourly: '1.356',
    freeTierHours: 0,
  },
  {
    id: 12,
    externalId: 'i3en.6xlarge',
    cloudProviderId: 1,
    memory: 196608,
    localDiskCount: 2,
    totalStorage: 15000,
    cpuCount: 24,
    networkSpeed: 25600,
    externalStorageNetworkSpeed: 0,
    costPerHour: '6.822',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '4.11',
    subscriptionCostHourly: '3.694790625',
    instanceCostHourly: '2.712',
    freeTierHours: 0,
  },
  {
    id: 13,
    externalId: 'i3en.12xlarge',
    cloudProviderId: 1,
    memory: 393216,
    localDiskCount: 4,
    totalStorage: 30000,
    cpuCount: 48,
    networkSpeed: 51200,
    externalStorageNetworkSpeed: 0,
    costPerHour: '13.643',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '8.219',
    subscriptionCostHourly: '7.38958125',
    instanceCostHourly: '5.424',
    freeTierHours: 0,
  },
  {
    id: 14,
    externalId: 'i3en.24xlarge',
    cloudProviderId: 1,
    memory: 786432,
    localDiskCount: 8,
    totalStorage: 60000,
    cpuCount: 96,
    networkSpeed: 102400,
    externalStorageNetworkSpeed: 0,
    costPerHour: '27.286',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '16.438',
    subscriptionCostHourly: '14.7791625',
    instanceCostHourly: '10.848',
    freeTierHours: 0,
  },
  {
    id: 62,
    externalId: 'i4i.large',
    cloudProviderId: 1,
    groupDefault: true,
    displayOrder: 48,
    memory: 16384,
    localDiskCount: 1,
    totalStorage: 486,
    cpuCount: 2,
    networkSpeed: 10240,
    externalStorageNetworkSpeed: 0,
    costPerHour: '0.34323',
    environment: 'PRODUCTION',
    licenseCostOnDemandPerHour: '0.17123',
    subscriptionCostHourly: '0.288376',
    instanceCostHourly: '0.172',
    instanceFamily: 'i4i',
    freeTier: true,
  },
];

export const fallbackCloudAccounts = [
  {
    id: 1,
    cloudProviderId: 1,
    owner: 'Scylla',
    state: 'ACTIVE',
  },
  {
    id: 200,
    cloudProviderId: 2,
    owner: 'Scylla',
    state: 'ACTIVE',
  },
];
