import { ContextHolder, useAuth } from '@frontegg/react';
import { isEmpty } from 'lodash/fp';
import { useState } from 'react';

import { useHasFeature } from 'components/providers/features/with-features';
import {
  SNACKBAR_VARIANTS,
  useSnackbar,
} from 'components/providers/notifications';
import { useMutationRequest, useQueryRequest } from 'hooks/fetch';
import { setDefaultAccount } from 'services/resources/account/account';
import { infoKey } from 'services/resources/me';
import { setAnalyticsUser } from 'utils/analytics';
import logger from 'utils/logger';

const apiUrl = process.env.apiUrl;

function useToken() {
  const { user } = useAuth();

  return user?.accessToken;
}

export function useAuthHeader() {
  const token = useToken();
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }

  return null;
}

export function useProfile() {
  const { isAuthenticated } = useAuth();

  const key = isAuthenticated && infoKey;

  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useDefaultAccount() {
  return useQueryRequest({
    key: `${apiUrl}/account/default`,
    withToken: true,
  });
}

export function useSwitchAccount() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutationRequest({
    requester: (id, token) =>
      setDefaultAccount.call({
        accountId: id,
        token,
      }),
    onSuccess: () => {
      // Frontegg recommends to refresh the user session - this will reload all frontegg data and our user data and permissions. See https://docs.frontegg.com/docs/switch-active-tenant-from-your-application
      window.location.reload();
    },
    onFailure: err =>
      enqueueSnackbar(`${err?.msg}\n trace-id: ${err?.traceId}`, {
        variant: SNACKBAR_VARIANTS.ERROR,
        key: `switch-account-error-${err?.traceId}`,
        persist: true,
      }),
  });
}

export function useAnalyticsIndetity() {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth();
  const { data: { profile } = {}, isLoading: isUserProfileLoading } =
    useProfile();

  const [identified, setIsIdentified] = useState(false);

  if (
    !identified &&
    isAuthenticated &&
    !(isAuthLoading || isUserProfileLoading) &&
    !isEmpty(profile)
  ) {
    setAnalyticsUser(profile);
    setIsIdentified(true);
  }
}

export function useAuthentication() {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth();
  const isFEggHostedEnabled = useHasFeature('FRONTEGG_HOSTED');

  const {
    data: { permissions, profile } = {},
    isLoading: isUserProfileLoading,
    error: isUserProfileError,
  } = useProfile();

  const isLoading =
    isAuthLoading || isUserProfileLoading || Boolean(isUserProfileError);

  if (isUserProfileError) {
    logger.error({
      prefix: 'hooks/authentication',
      message: 'isUserProfileError: ',
      error: isUserProfileError,
    });
  }

  function hasPermission(permission) {
    return permissions?.[permission];
  }

  function logout() {
    logger.warn({
      prefix: 'hooks/authentication',
      message: 'logout() has been triggered',
    });
    if (isFEggHostedEnabled) {
      const baseUrl = ContextHolder.getContext().baseUrl;
      window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`;
    } else {
      window.location.href = '/account/logout';
    }
  }

  return {
    isLoading,
    isAuthenticated,
    userProfile: profile,
    hasPermission,
    logout,
  };
}
