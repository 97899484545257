import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { useCallback, useState } from 'react';

import withStyles from 'components/withStylesAdapter';
import { useQueryRequest } from 'hooks/fetch';
import { usePrevious } from 'utils/hooks';

const apiUrl = process.env.apiUrl;

const withJss = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export function useVersionPolling() {
  const [showNotice, setShowNotice] = useState(false);
  const closeNotice = useCallback(() => {
    setShowNotice(false);
  }, [setShowNotice]);

  const { data } = useQueryRequest({
    key: `${apiUrl}/info/version`,
    options: {
      refreshInterval: process.env.versionPollingInterval,
    },
  });

  const commit = data?.frontend?.gitCommit;
  const previousCommit = usePrevious(commit);

  if (previousCommit && previousCommit !== commit && !showNotice) {
    setShowNotice(true);
  }

  return [showNotice, closeNotice];
}

function Version({ classes = {} }) {
  const [showNotice, closeNotice] = useVersionPolling();

  return (
    <Snackbar
      classes={{ root: classes.root }}
      id="versionUpdatedNotice"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={showNotice}
      autoHideDuration={6000}
      onClose={closeNotice}
      message="Scylla Cloud has been updated. Please refresh the page."
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          size="large"
          onClick={closeNotice}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
}

export default withJss(Version);
