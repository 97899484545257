import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import { ErrorBoundary } from 'components/common/error-boundary';
import withStyles from 'components/withStylesAdapter';

import Footer from './footer';
import Header from './header/header';

const withJss = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  header: {
    flex: '0 0 auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    backgroundColor: theme.colors.zircon,
    overflow: 'hidden',
    ...theme.extensions.responsiveFlexColumn(theme),
  },
  wrapper: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  subheader: {
    container: 'subheader / inline-size',
    flex: '0 0 auto',
  },
  content: {
    position: 'relative',
    flex: '1 1 100%',
    minHeight: 'min-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  capWidth: {
    maxWidth: theme.maxContentWidth,
    margin: '0 auto',
    backgroundColor: theme.colors.zircon,
    borderRight: `1px solid ${theme.colors.mercury}`,
    borderLeft: `1px solid ${theme.colors.mercury}`,
  },
}));
function AppLayout({ renderSubheader, renderContent, classes }) {
  const location = useLocation();

  const isNewClusterPage = location?.pathname.includes('clusters/new');
  const isAddDcPage = location?.pathname.includes('/add-dc');

  return (
    <div className={classes.root}>
      <Header outerClass={classes.header} />
      <div className={classes.body}>
        <div className={classes.wrapper} id="app-layout-wrapper">
          {Boolean(renderSubheader) && (
            <div className={classes.subheader}>{renderSubheader()}</div>
          )}
          <main className={classNames(classes.content, classes.capWidth)}>
            <ErrorBoundary>{renderContent()}</ErrorBoundary>
          </main>
          {!(isNewClusterPage || isAddDcPage) && <Footer />}
        </div>
      </div>
    </div>
  );
}

AppLayout.propTypes = {
  renderSubheader: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

export default withJss(AppLayout);
