const cloudDocsUrl = process.env.scyllaCloudDocsUrl;
const iotExamplesUrl = process.env.scyllaIotExamplesUrl;
const osDocsUrl = process.env.scyllaOSDocsUrl;
const enterpriseDocsUrl = process.env.scyllaEnterpriseDocsUrl;
const gettingStartedDocsUrl = process.env.scyllaGettingStartedDocsUrl;
const universityUrl = process.env.scyllaUniversityUrl;

const cloudDocs = {
  clusterConnections: {
    base: formatUrl(cloudDocsUrl, 'cluster-connections'),
    awsTgwVpcAttachment: formatUrl(
      cloudDocsUrl,
      'cluster-connections/aws-tgw-vpc-attachment'
    ),
    awsTgwVpcAttachmentTroubleshootingShareInvitation: formatUrl(
      cloudDocsUrl,
      'cluster-connections/aws-tgw-vpc-attachment#aws-tgw-troubleshooting-send-resource-share-invitation'
    ),
    awsTgwVpcAttachmentTroubleshootingTransitGateway: formatUrl(
      cloudDocsUrl,
      'cluster-connections/aws-tgw-vpc-attachment#aws-tgw-troubleshooting-share-transit-gateway'
    ),
    awsTgwVpcAttachmentTroubleshootingAcceptAttachment: formatUrl(
      cloudDocsUrl,
      'cluster-connections/aws-tgw-vpc-attachment#aws-tgw-troubleshooting-accept-tgw-attachment'
    ),
    awsTgwVpcAttachmentRouteTraffic: formatUrl(
      cloudDocsUrl,
      'cluster-connections/aws-tgw-vpc-attachment#aws-tgw-route-traffic'
    ),
    awsVpcPeering: formatUrl(
      cloudDocsUrl,
      'cluster-connections/aws-vpc-peering'
    ),
    gcpVpcPeering: formatUrl(
      cloudDocsUrl,
      'cluster-connections/gcp-vpc-peering'
    ),
    gcpVpcPeeringProjId: formatUrl(
      cloudDocsUrl,
      'cluster-connections/gcp-vpc-peering#proj-id'
    ),
  },
  cloudServices: {
    autoscaleAndResizing: formatUrl(
      cloudDocsUrl,
      'cloud-services/scylla-cloud-autoscale-and-resizing'
    ),
    cloudBackup: formatUrl(cloudDocsUrl, 'cloud-services/cloud-backup'),
    supportAlertsSLA: formatUrl(
      cloudDocsUrl,
      'cloud-services/scylla-cloud-support-alerts-sla'
    ),
    managingVersions: formatUrl(
      cloudDocsUrl,
      'cloud-services/scylla-cloud-managin-versions'
    ),
  },
  cloudSetup: {
    byoa: formatUrl(cloudDocsUrl, 'cloud-setup/scylla-cloud-byoa'),
    byoaAwsAccountLimits: formatUrl(
      cloudDocsUrl,
      'cloud-setup/scylla-cloud-byoa#aws-account-limits'
    ),
    byoaDefineBoundaryPolicy: formatUrl(
      cloudDocsUrl,
      'cloud-setup/scylla-cloud-byoa#define-a-boundary-policy'
    ),
    byoaCreateCloudPolicy: formatUrl(
      cloudDocsUrl,
      'cloud-setup/scylla-cloud-byoa#create-a-scylladb-cloud-policy'
    ),
    byoaCreateCloudRole: formatUrl(
      cloudDocsUrl,
      'cloud-setup/scylla-cloud-byoa#create-a-scylladb-cloud-role'
    ),
    promProxy: formatUrl(cloudDocsUrl, 'cloud-setup/cloud-prom-proxy'),
  },
  security: {
    base: formatUrl(cloudDocsUrl, 'security'),
    encryptionAtRest: formatUrl(cloudDocsUrl, 'security/encryption-at-rest'),
    encryptionAtRestBYOK: formatUrl(
      cloudDocsUrl,
      'security/encryption-at-rest#ear-bring-your-own-key'
    ),
    accessManagement: formatUrl(
      cloudDocsUrl,
      'access-management/user-management'
    ),
  },
  scyllaDbQuickstart: {
    base: formatUrl(cloudDocsUrl, 'scylladb-quickstart'),
  },
  billingUsage: {
    storageDataDelay: formatUrl(
      cloudDocsUrl,
      'manage-clusters/usage#backup-storage'
    ),
    networkTypes: formatUrl(
      cloudDocsUrl,
      'manage-clusters/usage#usage-network-types'
    ),
  },
};

const ioTExamplesDocs = {
  buildWithGo: formatUrl(iotExamplesUrl, 'build-with-go'),
  buildWithJavascript: formatUrl(iotExamplesUrl, 'build-with-javascript'),
  buildWithJava: formatUrl(iotExamplesUrl, 'build-with-java'),
  buildWithPython: formatUrl(iotExamplesUrl, 'build-with-python'),
};

const osDocs = {
  cql: {
    ddlCreateKeyspace: formatUrl(
      osDocsUrl,
      'cql/ddl#create-keyspace-statement'
    ),
  },
  usingScylla: {
    drivers: formatUrl(osDocsUrl, 'using-scylla/drivers'),
  },
  operatingScylla: {
    security: {
      authorizationAlterRole: formatUrl(
        osDocsUrl,
        'operating-scylla/security/authorization#alter-role'
      ),
      authorizationCreateRole: formatUrl(
        osDocsUrl,
        'operating-scylla/security/authorization#create-role'
      ),
    },
  },
  alternator: {
    alternatorSpecificApi: formatUrl(
      osDocsUrl,
      'alternator/alternator#alternator-specific-api'
    ),
  },
};

const driverDocs = {
  java: 'https://java-driver.docs.scylladb.com/stable/',
  python: 'https://python-driver.docs.scylladb.com/stable/',
};

const enterpriseDocs = {
  gettingStarted: {
    systemRequirementsDiskSpace: formatUrl(
      enterpriseDocsUrl,
      'getting-started/system-requirements#disk-space'
    ),
  },
};

const gettingStartedDocs = {
  java: formatUrl(gettingStartedDocsUrl, 'build-with-java'),
  golang: formatUrl(gettingStartedDocsUrl, 'build-with-golang'),
  python: formatUrl(gettingStartedDocsUrl, 'build-with-python'),
  javascript: formatUrl(gettingStartedDocsUrl, 'build-with-javascript'),
  other: formatUrl(gettingStartedDocsUrl, ''),
  alternator: formatUrl(gettingStartedDocsUrl, 'alternator/getting-started'),
  alternatorPython: formatUrl(
    gettingStartedDocsUrl,
    'alternator/build-with-python'
  ),
};

const universityResources = {
  cloudLandingPage: formatUrl(universityUrl, 'scylla-cloud-landing-page'),
};

function formatUrl(base, path) {
  return `${base}/${path}`.replace(/[^:]\/\//g, '/');
}

export const documentationLinks = {
  cloudDocs,
  ioTExamplesDocs,
  osDocs,
  driverDocs,
  enterpriseDocs,
  gettingStartedDocs,
  universityResources,
};
