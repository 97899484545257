import { Grow, Alert as MuiAlert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import withStyles from 'components/withStylesAdapter';
import { errorDark } from 'definitions/mui/colors';

const withJss = withStyles({
  failureTitle: {
    color: errorDark,
  },
  alertText: {
    fontWeight: 400,
    fontSize: '0.875rem',
  },
  actions: {
    display: 'flex',
    paddingLeft: '2rem',
    gap: '1rem',
    '& > *': {
      cursor: 'pointer',
    },
  },
});

function Alert({
  id,
  status = 'success',
  title,
  text,
  onClose,
  visible,
  timeout,
  classes,
  actions,
  variant = 'outlined',
  className,
}) {
  const alertRef = useRef(null);

  const prevVisibleRef = useRef({});
  useEffect(
    function scrollToAlertOnShow() {
      if (!prevVisibleRef.current && visible) {
        // After react 18 upgrade, this can't be done synchronously
        setTimeout(
          () => alertRef.current?.scrollIntoView({ behavior: 'smooth' }),
          100
        );
      }

      prevVisibleRef.current = visible;
    },
    [visible]
  );

  if (!visible) return null;

  const alertIdStatus = status === 'error' ? 'failure' : status;

  return (
    <Grow in={visible} timeout={timeout}>
      <MuiAlert
        variant={variant}
        severity={status}
        id={id ? `alert-${status}-${id}` : null}
        data-testid={`alert-${status}`}
        ref={alertRef}
        onClick={onClose}
        className={className}
      >
        {title && (
          <AlertTitle
            id={`alert-title-${alertIdStatus}-${id}`}
            className={status === 'error' ? classes.failureTitle : null}
            style={{ marginBottom: text ? '' : '0px' }}
          >
            <span>{title}</span>
            {actions && (
              <span className={classes.actions}>
                {actions.map(a => (
                  <span onClick={a.clickHandler} id={a.label} key={a.label}>
                    {a.label}
                  </span>
                ))}
              </span>
            )}
          </AlertTitle>
        )}
        <span id={`alert-text-${status}-${id}`} className={classes.alertText}>
          {text}
        </span>
      </MuiAlert>
    </Grow>
  );
}

Alert.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.node,
  status: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  timeout: PropTypes.number,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      clickHandler: PropTypes.func,
    })
  ),
  className: PropTypes.string,
};

export default withJss(Alert);
