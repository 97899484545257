import * as parsers from 'services/resources/parsers';
import { createServerResource } from 'services/server-resource';

const apiUrl = process.env.apiUrl;

export const setAccountBillingInfo = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/billing/info`,
  {
    inputMap: {
      billingToken: 'billingToken',
      companyName: 'companyName',
      country: 'country',
      state: 'state',
      city: 'city',
      zip: 'zip',
      address: 'address',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const setAccountCountry = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/country`,
  {
    inputMap: {
      id: 'id',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const setDefaultAccount = createServerResource(
  'post',
  `${apiUrl}/account/default`,
  {
    inputMap: {
      accountId: 'accountId',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const setNotificationEmail = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/notifications/email`,
  {
    inputMap: {
      emails: 'emails',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);
