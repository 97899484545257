export function logRequestErrors(error, fullUrl, traceId) {
  // recreating error here to keep the right stack trace
  const inPlaceError = new Error(error);

  console.error(
    `[Error - API request]: ${fullUrl}\n`,
    inPlaceError,
    '\n',
    traceId ? `TraceId: ${traceId}` : ''
  );
}

export function rethrowNewError(error, extendedInfo) {
  throw extendedInfo
    ? {
        error,
        ...extendedInfo,
      }
    : error;
}
