import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import AlertWithTraceId from 'components/common/alert-with-trace-id';
import MainModal, {
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
  MainModalHeader,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { useDisableClusterMetrics } from 'hooks/cluster';
import { SUCCESS } from 'services/translations/notifications';

const withJss = withStyles(theme => ({
  caption: {
    margin: '10px 0 0',
  },
  alert: {
    margin: '20px 0',
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  iconContainer: {
    backgroundColor: theme.colors.mistyLightRose,
    border: `${theme.spacing(1)} solid ${theme.colors.lavenderBlush}`,
    borderRadius: '50%',
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disableButton: {
    ...theme.extensions.customButton(),
  },
}));

function DisableClusterMetricsModal({
  hideModal,
  classes,
  params: { clusterId },
}) {
  const theme = useTheme();
  const { isLoading, request, error, data } =
    useDisableClusterMetrics(clusterId);
  const isSuccess = Boolean(data);

  return (
    <MainModal onClose={hideModal} maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<DeleteOutline fontSize="medium" />}
        helperIconColor={theme.colors.vermilion}
      >
        Disable Cluster Metrics
      </MainModalHeader>
      <MainModalContent>
        <AlertWithTraceId
          isSuccess={isSuccess}
          successTitle={SUCCESS.CLUSTER.DISABLE}
          error={error}
          className={classes.alert}
          id="disableClusterMetrics"
        />
        {!isSuccess && (
          <Typography paragraph>
            This will disable the Cluster Metrics extraction and data. Are you
            sure you want to disable?
          </Typography>
        )}
      </MainModalContent>
      <MainModalActions>
        {isSuccess ? (
          <MainModalActionButton
            id="disableGoBackBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={hideModal}
            fullWidth
          >
            CLOSE
          </MainModalActionButton>
        ) : (
          <div className={classes.buttonsContainer}>
            <MainModalActionButton
              id="disableExtractClusterMetricsBtn"
              type={MainModalActionButton.Types.MAJOR}
              /*
                To prevent syntetic event from being passed to the request function,
                we have to use an arrow function here, otherwise the event will be passed as an argument
                and token will not be passed to the requester
              */
              onClick={() => request()}
              isLoading={isLoading}
              className={classes.disableButton}
            >
              DISABLE
            </MainModalActionButton>
            <MainModalActionButton
              id="disableClusterMetricsCancelBtn"
              type={MainModalActionButton.Types.MINOR}
              onClick={hideModal}
            >
              CANCEL
            </MainModalActionButton>
          </div>
        )}
      </MainModalActions>
    </MainModal>
  );
}

DisableClusterMetricsModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    clusterId: PropTypes.string,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(DisableClusterMetricsModal);
