import { createRoot } from 'react-dom/client';

import Main from './main';

function initialize() {
  console.log(
    `%c Scylla Cloud: '${process.env.NODE_ENV}' build ${process.env.REACT_APP_BUILD_COMMIT} deployed to '${process.env.REACT_APP_DEST}' at ${process.env.REACT_APP_DEPLOY_DATE}`,
    'color: green; font-weight: bold'
  );

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(<Main />);
}

initialize();
