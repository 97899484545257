import CloseIcon from '@mui/icons-material/Close';
import { alpha } from '@mui/material';
import { Typography, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import RoundPattern from 'assets/icons/round-back-pattern.svg';
import withStyles from 'components/withStylesAdapter';
import { infoGreen } from 'definitions/mui/colors';

const withJss = withStyles((theme, { helperIconColor = infoGreen }) => ({
  withPattern: {
    backgroundImage: `url(${RoundPattern})`,
    backgroundRepeat: 'no-repeat',
  },
  topBar: {
    position: 'relative',
    height: '4.75rem',
    zIndex: '0',
  },
  close: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    color: theme.colors.darkKnight,
  },
  iconContainer: {
    backgroundColor: alpha(helperIconColor, 0.2),
    outline: `${theme.spacing(1)} solid ${alpha(helperIconColor, 0.1)}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    top: '1.5rem',
    left: '1.5rem',
    position: 'absolute',
    width: '3rem',
    height: '3rem',

    color: helperIconColor,
  },
  titleWrapper: {
    position: 'relative',
    zIndex: '1',
    padding: '1rem 1.5rem 0',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.extensions.modalTitleBig,
  },
}));

function MainModalHeader({
  children,
  onClose,
  classes,
  HelperIcon,
  testid = 'modalHeaderTitle',
}) {
  return (
    <div className={HelperIcon ? classes.withPattern : ''}>
      {HelperIcon ? (
        <div className={classes.topBar}>
          <div className={classes.iconContainer}>{HelperIcon}</div>
          {onClose ? (
            <IconButton
              onClick={onClose}
              className={classes.close}
              data-testid="close-icon"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
      ) : null}
      <div className={classes.titleWrapper}>
        <Typography className={classes.headerTitle} data-testid={testid}>
          {children}
        </Typography>
        {onClose && !HelperIcon ? (
          <IconButton
            onClick={onClose}
            className={classes.close}
            data-testid="close-icon"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
}

MainModalHeader.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  classes: PropTypes.shape({}),
  HelperIcon: PropTypes.node,
  helperIconColor: PropTypes.string,
  testid: PropTypes.string,
};

export default withJss(MainModalHeader);
