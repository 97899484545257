import { MenuItem } from '@mui/material';

import withStyles from 'components/withStylesAdapter';
import { useAuthentication, useSwitchAccount } from 'hooks/authentication';

const withJss = withStyles(theme => ({
  disabledItem: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.colors.sonicSilver,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    '&.Mui-disabled': {
      opacity: 'unset',
    },
  },
}));

function AccountSelection({ classes = {} }) {
  const { userProfile: { accounts, defaultAccountId } = {} } =
    useAuthentication();

  const { request: switchAccount } = useSwitchAccount();

  return (
    <>
      <MenuItem className={classes.disabledItem} disabled>
        Other Accounts
      </MenuItem>
      {accounts
        .filter(a => a.id !== defaultAccountId)
        .map(a => (
          <MenuItem
            key={a.id}
            className={classes.menuItem}
            onClick={() => {
              switchAccount(a.id);
            }}
            data-testid={`dropdownOtherAccount${a.id}`}
          >
            {a.name}
          </MenuItem>
        ))}
    </>
  );
}

export default withJss(AccountSelection);
