import { useSignUpState } from '@frontegg/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  link: { ...theme.extensions.hyperLink, color: theme.colors.pictonBlue },
  container: {
    marginTop: theme.spacing(2),
  },
}));

export const stepDescriptions = {
  SignUp: 'up',
  Login: 'in',
};

function TermsComponent({ classes, stepDescription }) {
  const { stage } = useSignUpState();

  return stage === 'SignUp' ? (
    <Typography align="center" className={classes.container}>
      By Signing {stepDescription} I confirm I have read and accept ScyllaDB’s
      <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.scylladb.com/scylla-cloud-terms-of-service/"
      >
        Terms of Service
      </a>{' '}
      and that my information will be handled as detailed in ScyllaDB’s{' '}
      <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.scylladb.com/privacy/"
      >
        Privacy Policy
      </a>
    </Typography>
  ) : null;
}

TermsComponent.prototype = {
  step: PropTypes.oneOf(Object.values(stepDescriptions)).isRequired,
};

export const Terms = withJss(TermsComponent);
