export const white = '#ffffff';
export const black = '#000000';
export const dimGray = '#212121';
export const charcoal = '#4A4A4A';
export const mercury = '#e7e7e7';
export const alabaster = '#fafafa';
export const azul = '#216ce8';
export const electricEel = '#86b2f2';
export const sanMarino = '#5064B2';
export const adamantineBlue = '#42a5f5';
export const pictonBlue = '#42C4E6';
export const darkPictonBlue = '#2C98F0';
export const celticBlue = '#1B6BC7';
export const mako = '#404451';
export const deepDarkMarino = '#0F277E';
export const brightNavyBlue = '#1976d2';
export const darkSanMarino = '#4458A3';
export const periwinkleGray = '#D4D7E9';
export const baliHai = '#868FAC';
export const mystic = '#E5E8F1';
export const monza = '#D0021B';
export const chablis = '#FFF6F6';
export const zircon = '#F6F8FF';
export const antiFlashWhite = '#F7F5F2';
export const ghosthWhite = '#FBFAF9';
export const malachite = '#18C746';
export const rhino = '#2C405A';
export const error = '#f44336';
export const errorDark = '#b42318';
export const comet = '#5f6788';
export const flintstone = '#667085';
export const whiteLilac = '#E5E8F6';
export const whisper = '#F4F5F9';
export const honeydew = '#eafff5';
export const mistyrose = '#ffeae1';
export const athensGray = '#f2f3f6';
export const rhythm = '#717BA3';
export const shipCove = '#697ABD';
export const dodgerBlue = '#44A6F8';
export const nepal = '#8DABC4';
export const lightSteelBlue = '#ABC5D8';
export const brightBlueBell = '#98a3cd';
export const nepalFade = 'rgba(141, 171, 196, 0.5)';
export const muleFawn = '#8B572A';
export const buttercup = '#F5A623';
export const casablanca = '#F7B74F';
export const pearlLusta = '#FDF5E6';
export const dustyGray = '#999999';
export const dustyGrayLight = '#616161';
export const catskillWhite = '#F0F2F8';
export const mineShaft = '#333333';
export const internationalOrange = '#FF5300';
export const caribbeanGreen = '#00DA7B';
export const wildSand = '#F4F4F4';
export const sanMarinoBlue = '#4A5DA6';
export const paleGreen = '#f5fef7';
export const silverMedal = '#d6d6d6';
export const blueZephyr = '#5b6578';
export const darkKnight = '#101828';
export const antiflashWhite = '#ecf0f8';
export const darkOrchid = '#9c27b0';
export const sonicSilver = '#757575';
export const whiteSmoke = '#f5f5f5';
export const superSilver = '#eeeeee';
export const karimunBlue = '#2196f3';
export const googleBlue = '#1E88E5';
export const gray = '#808080';
export const brightGray = '#efefef';
export const lightGray = '#d8d8d8';
export const snowflake = '#f0f0f0';
export const chineseWhite = '#e0e0e0';
export const actionActive = '#0000008F';
export const ghostWhite = '#f9fafb';
export const midGray = '#475467';
export const satinWhite = '#d0d5dd';
export const errigalWhite = '#f3f2f4';

export const tableCellHighlighted = '#fcf8e3';

export const errorAlt = '#a9211d';
export const errorLight = '#fbc6c2';
export const errorPale = '#fef1f0';

export const success = '#106e42';
export const successLight = '#bef9d6';
export const successPale = '#eafdf2';

export const warning = '#b54708';
export const warningLight = '#ffecb8';
export const warningPale = '#fff9e9';

export const info = '#026aa2';
export const infoLight = '#c0e5fb';
export const infoPale = '#eef8fe';

export const vermilion = '#D92D20';
export const mistyLightRose = '#FEE4E2';
export const lavenderBlush = '#FEF3F2';

export const spanishGreen = '#039855';
export const aeroBlue = '#D1FADF';
export const honeyDewLight = '#ECFDF3';

export const chipGreen = '#4caf50';
export const chipBgGreen = '#e8f5e9';

export const chipOrange = '#f57c00';
export const chipBgOrange = '#fff3e0';

export const chipPurple = '#512da8';
export const chipBgPurple = '#ede7f6';

export const infoGreen = '#12B76A';

export const violet = '#7F56D9';
export const violetLight = '#D6BBFB';
export const violetTransparent = '#9E77ED3D';
