import * as parsers from 'services/resources/parsers';
import { createServerResource } from 'services/server-resource';

const apiUrl = process.env.apiUrl;

export const createCloudAccount = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cloud-account`,
  {
    inputMap: {
      cloudProviderId: 'cloudProviderId',
    },
    parsers: [
      parsers.errorParser,
      ({ data: account }) => parsers.cloudAccountParser(account),
    ],
  }
);

export const setCloudAccountProperties = createServerResource(
  'put',
  `${apiUrl}/account/{{accountId}}/cloud-account/{{cloudAccountId}}/properties`,
  {
    inputMap: {
      awsAccountId: 'awsAccountId',
      boundaryPolicyArn: 'boundaryPolicyArn',
      cloudPolicyArn: 'cloudPolicyArn',
      cloudRoleArn: 'cloudRoleArn',
      cloudRoleExternalId: 'cloudRoleExternalId',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const deleteCloudAccount = createServerResource(
  'delete',
  `${apiUrl}/account/{{accountId}}/cloud-account/{{cloudAccountId}}`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const cloudAccountAwsCloudPolicy = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cloud-account/{{cloudAccountId}}/aws-cloud-policy`,
  {
    inputMap: {
      cloudPolicyName: 'cloudPolicyName',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);
