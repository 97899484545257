export const rotate = 'rotate-animation';

export const loader = 'loader-animation';

export const variables = {
  loader: {
    duration: 16,
    cubeSide: 50,
  },
};

const loaderAnimCubeStep = variables.loader.cubeSide - 1;

export const keyframesDict = {
  [`@keyframes ${rotate}`]: {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  [`@keyframes ${loader}`]: {
    '0%': {
      transform: 'translateX(0) translateY(0)',
    },
    '6.25%': {
      transform: `translateX(${0.5 * loaderAnimCubeStep}px) translateY(0px)`,
    },
    '12.5%': {
      transform: `translateX(${loaderAnimCubeStep}px) translateY(0px)`,
    },
    '18.75%': {
      transform: `translateX(${loaderAnimCubeStep}px) translateY(0px)`,
    },
    '25%': {
      transform: `translateX(${loaderAnimCubeStep}px) translateY(0px)`,
    },
    '31.25%': {
      transform: `translateX(${1.5 * loaderAnimCubeStep}px) translateY(0px)`,
    },
    '37.5%': {
      transform: `translateX(${2 * loaderAnimCubeStep}px) translateY(0px)`,
    },
    '43.75%': {
      transform: `translateX(${2 * loaderAnimCubeStep}px) translateY(${
        0.5 * loaderAnimCubeStep
      }px)`,
    },
    '50%': {
      transform: `translateX(${
        2 * loaderAnimCubeStep
      }px) translateY(${loaderAnimCubeStep}px)`,
    },
    '56.25%': {
      transform: `translateX(${
        1.5 * loaderAnimCubeStep
      }px) translateY(${loaderAnimCubeStep}px)`,
    },
    '62.5%': {
      transform: `translateX(${loaderAnimCubeStep}px) translateY(${loaderAnimCubeStep}px)`,
    },
    '68.75%': {
      transform: `translateX(${loaderAnimCubeStep}px) translateY(${loaderAnimCubeStep}px)`,
    },
    '75%': {
      transform: `translateX(${loaderAnimCubeStep}px) translateY(${loaderAnimCubeStep}px)`,
    },
    '81.25%': {
      transform: `translateX(${
        0.5 * loaderAnimCubeStep
      }px) translateY(${loaderAnimCubeStep}px)`,
    },
    '87.5%': {
      transform: `translateX(0px) translateY(${loaderAnimCubeStep}px)`,
    },
    '93.75%': {
      transform: `translateX(0px) translateY(${0.5 * loaderAnimCubeStep}px)`,
    },
    '100%': {
      transform: 'translateX(0) translateY(0)',
    },
  },
};
