import { internalParamValues } from 'components/pages/new-cluster/Calculator/CalculatorSpecChooser';
import {
  calculateClusterCapacity,
  mapInstanceProperties,
} from 'components/pages/new-cluster/Calculator/PricingModels/scylla-pricing-model.ts';
import { getNewClusterDefaults } from 'components/pages/new-cluster/hooks/deployment';
import { INSTANCE_TYPE_ENVIRONMENT } from 'services/constants';

export function instanceToClusterSpec(
  instance,
  numberOfNodes = getNewClusterDefaults().numberOfNodes,
  replicationFactor
) {
  const clusterSpec = {
    instanceType: mapInstanceProperties(instance),
    nodes: numberOfNodes,
  };

  const clusterCapacity = calculateClusterCapacity(
    clusterSpec,
    replicationFactor,
    internalParamValues
  );

  const storage =
    clusterCapacity.storage /
    replicationFactor /
    internalParamValues.compactionOverhead;

  return {
    id: instance.id,
    instance,
    numberOfNodes,
    totalVCPUs: clusterCapacity.vcpu,
    totalMemory: numberOfNodes * instance.memory,
    totalStorage: storage.toFixed(3),
    peakLoad: isDevelopmentInstance(instance) ? 0 : clusterCapacity.peakLoad,
    sustainedLoad: isDevelopmentInstance(instance)
      ? 0
      : clusterCapacity.sustainedLoad,
  };
}

export function isDevelopmentInstance(instance) {
  return instance?.environment === INSTANCE_TYPE_ENVIRONMENT.DEVELOPMENT;
}
