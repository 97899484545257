import { useAuth } from '@frontegg/react';
import { isValid } from 'date-fns';
import { get } from 'lodash/fp';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useQueryRequest } from 'hooks/fetch';
import { SORT_DIRECTIONS } from 'services/constants';

const apiUrl = process.env.apiUrl;

function useSupportUrl() {
  return useQueryRequest({
    key: `${apiUrl}/me/support`,
    withToken: true,
  });
}

export function useRedirect(url, error, redirectMessage, onRedirect) {
  const [redirect, setRedirect] = useState({
    redirectWindow: null,
    shouldRedirect: false,
  });
  useEffect(
    function redirectToUrl() {
      const { redirectWindow, shouldRedirect } = redirect;
      if (error && shouldRedirect && redirectWindow) {
        redirectWindow.document.write('FAILED!');
        redirectWindow.stop();
        setRedirect({ shouldRedirect: false });
      }
      if (url && shouldRedirect && redirectWindow) {
        redirectWindow.location = url;
        setRedirect({ shouldRedirect: false });
      }
    },
    [error, url, redirect]
  );

  return useCallback(
    function openUrlInNewWindow(event) {
      event.preventDefault();
      onRedirect && onRedirect();
      const redirectWindow = window.open('', '_blank');
      redirectWindow.document.write(redirectMessage);
      setRedirect({ redirectWindow, shouldRedirect: true });
    },
    [setRedirect, redirectMessage, onRedirect]
  );
}

export function useSupportRedirect(ticketId) {
  const { error, data, isValidating, mutate } = useSupportUrl();

  const returnParam = ticketId
    ? `&return_to=${process.env.guestSupportUrl}/hc/requests/${ticketId}`
    : '';

  const url = `${data?.supportUrl}${returnParam}`;

  const redirect = useRedirect(url, error, 'Redirecting to support...');

  const redirectToSupport = async e => {
    await mutate();
    redirect(e);
  };

  return {
    redirectToSupport,
    isLoading: isValidating,
  };
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function reverseSortingOrder(order) {
  return order === SORT_DIRECTIONS.ASCENDING
    ? SORT_DIRECTIONS.DESCENDING
    : SORT_DIRECTIONS.ASCENDING;
}

export const sortKeyPrefix = '_sortKey_';
function byProperty(objA, objB, property) {
  const sortProperty = `${sortKeyPrefix}${property}`;
  const propA = get(sortProperty)(objA) || get(property)(objA);
  const propB = get(sortProperty)(objB) || get(property)(objB);

  const dateA = new Date(propA);
  const dateB = new Date(propB);

  if (isValid(dateA) && isValid(dateB)) {
    return dateA - dateB;
  }

  return String(propA).localeCompare(String(propB));
}

export function useTableSorting(
  compareByProp = byProperty,
  defaultProperty,
  defaultDirection = SORT_DIRECTIONS.ASCENDING
) {
  const [sortBy, setSortBy] = useState({
    prop: defaultProperty,
    dir: defaultDirection,
  });

  const handleSortChange = useCallback(
    key => {
      setSortBy({
        prop: key,
        dir:
          sortBy.prop === key
            ? reverseSortingOrder(sortBy.dir)
            : SORT_DIRECTIONS.ASCENDING,
      });
    },
    [sortBy]
  );

  const sortFunction = useCallback(
    array =>
      array?.sort((a, b) =>
        sortBy.dir === SORT_DIRECTIONS.ASCENDING
          ? compareByProp(a, b, sortBy.prop)
          : -compareByProp(a, b, sortBy.prop)
      ),
    [sortBy, compareByProp]
  );

  useEffect(() => {
    setSortBy({
      prop: defaultProperty,
      dir: defaultDirection,
    });
  }, [defaultProperty, defaultDirection]);

  return { sortBy, handleSortChange, sortFunction };
}

/**
 * Retrieve the value of a specific property from the account metadata stored in frontegg.
 *
 * @param {string} propertyName - The name of the property to retrieve.
 * @return {any} The value of the specified property from the account metadata. Note that siren stores values as strings that might need additional parsing
 */
export function useAccountMetadataProperty(propertyName) {
  const authInfo = useAuth();
  const accountRawMetadata = authInfo?.tenantsState?.activeTenant?.metadata;
  const metadata = accountRawMetadata ? JSON.parse(accountRawMetadata) : null;

  return metadata?.[propertyName];
}
