import { useRecurly } from '@recurly/react-recurly';
import { isEmpty } from 'lodash/fp';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getRecurlyId } from 'components/common/recurly/get-recurly-id';
import {
  FORM_DEFAULT_CONFIG,
  useMuiForm,
} from 'components/pages/new-cluster/hooks/form';
import { PaymentContext } from 'components/providers/payment';
import { useCountries } from 'hooks/account';
import { traceAndNormalizeRecurlyError } from 'services/recurly';

const usePaymentForm = () => {
  const recurly = useRecurly();
  const { country, setCountry } = useContext(PaymentContext);
  const { countries } = useCountries();

  const [isLoading, setIsLoading] = useState(true);
  const [isRecurlyLoading, setIsRecurlyLoading] = useState(false);

  const [recurlyErrors, setRecurlyErrors] = useState({});
  const [submitError, setSubmitError] = useState(null);

  const hookForm = useMuiForm(FORM_DEFAULT_CONFIG, {});

  const { getValues, setValue } = hookForm;
  const { isValid: isHookFormValid } = hookForm?.formState || {};
  const currentCountryValue = getValues('country');

  const [isRecurlyReady, setIsRecurlyReady] = useState(true);

  const setFields = useCallback(
    fields => {
      Object.entries(fields).forEach(([name, value]) => setValue(name, value));
    },
    [setValue]
  );

  // check if each part of the form state is valid
  const isOverallFormValid =
    isHookFormValid && isEmpty(recurlyErrors) && !submitError;

  const submitPaymentData = useCallback(
    async formRef => {
      const isHookFormValid = await hookForm.trigger();
      if (!isHookFormValid || !isEmpty(recurlyErrors)) {
        const invalidFormError = { message: 'Please complete the form first' };
        setSubmitError(invalidFormError);
        throw new Error(invalidFormError.message);
      }

      return new Promise((resolve, reject) => {
        setIsRecurlyLoading(true);

        recurly.token(formRef.current, async (err, token) => {
          if (err) {
            const recurlyError = await traceAndNormalizeRecurlyError(err);

            setSubmitError({
              ...recurlyError,
              message: 'Please provide valid credit card information',
            });
            setIsRecurlyLoading(false);
            reject(recurlyError);
          } else {
            setSubmitError(null);
            setIsRecurlyLoading(false);
            resolve(token);
          }
        });
      });
    },
    [recurly, hookForm, setSubmitError, recurlyErrors]
  );

  useEffect(
    function updateErrorsOnValidityChange() {
      if (isHookFormValid && isEmpty(recurlyErrors)) {
        setSubmitError(null);
      }
    },
    [isHookFormValid, recurlyErrors]
  );

  useEffect(
    function updateRecurlyOnCountryChange() {
      if (currentCountryValue !== country && Boolean(currentCountryValue)) {
        setCountry(currentCountryValue);
        if (countries && currentCountryValue) {
          const { group: countryGroup } =
            countries?.find(ctr => ctr.code === currentCountryValue) || {};

          // this is a workaround to re-create the Recurly CardElement in the form, so it uses correct api keys
          const recurlyKey = getRecurlyId(countryGroup);
          if (
            recurly.config.publicKey &&
            recurly.config.publicKey !== recurlyKey
          ) {
            setIsRecurlyReady(false);
            recurly.configure({ publicKey: recurlyKey });
            setTimeout(() => {
              setIsRecurlyReady(true);
            }, 200);
          }
        }
      }
    },
    [country, setCountry, currentCountryValue, countries, recurly]
  );

  return {
    state: {
      isValid: isOverallFormValid,
      isLoading,
      isRecurlyLoading,
      error: submitError,
    },
    hookForm,
    submitPaymentData,
    recurlyErrors,
    setRecurlyErrors,
    isRecurlyReady,
    setFields,
    getFields: getValues,
    setIsLoading,
  };
};

export { usePaymentForm };
