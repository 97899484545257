const DEFAULT_RETRY_MAX = 3;
const DEFAULT_RETRY_WAIT = null;
const checkResultDefault = undefined;
const checkErrorDefault = undefined;

const RETRY_ERROR_MESSAGE = '__RETRY_ERROR__';

// generator error for retry
export const createRetryError = originalError => {
  const retryError = new Error(RETRY_ERROR_MESSAGE);
  retryError.originalError = originalError;
  return retryError;
};

const retry = (promiseFn, retriesLeft = 1, retryWait) =>
  promiseFn().catch(err => {
    // retry only if got retryError
    if (err.message === RETRY_ERROR_MESSAGE) {
      const attemptsLeft = Math.max(1, retriesLeft);
      if (attemptsLeft === 1) {
        throw err.originalError || err;
      }
      const retryAgain = () => retry(promiseFn, attemptsLeft - 1, retryWait);
      if (Number.isFinite(retryWait) && retryWait >= 0) {
        return new Promise(resolve =>
          setTimeout(() => resolve(retryAgain()), retryWait)
        );
      }
      return retryAgain();
    }
    throw err;
  });

export const createRetryResourceMiddleware =
  ({
    defaultRetryMax = DEFAULT_RETRY_MAX,
    defaultRetryWait = DEFAULT_RETRY_WAIT,
    defaultCheckResult = checkResultDefault,
    defaultCheckError = checkErrorDefault,
  } = {}) =>
  next =>
  (...args) => {
    const {
      retryMax = defaultRetryMax,
      retryWait = defaultRetryWait,
      retryCheckResult = defaultCheckResult,
      retryCheckError = defaultCheckError,
      ...resourceOptions
    } = args[2] || {};

    // create the resource
    const dbaasResource = next(...args.slice(0, 2), resourceOptions);

    // override the resource call method to automatically add the header token
    const resourceCallMethod = dbaasResource.call;
    dbaasResource.call = (...callArgs) => {
      const payload = { ...(callArgs[0] || {}) };
      return retry(
        () =>
          resourceCallMethod(payload, ...callArgs.slice(1)).then(
            retryCheckResult,
            retryCheckError
          ),
        retryMax,
        retryWait
      );
    };

    return dbaasResource;
  };
