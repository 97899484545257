const addHeadTag = (tagName, properties = {}, events = {}) => {
  const tag = document.createElement(tagName);
  Object.keys(properties).forEach(k => {
    tag[k] = properties[k];
  });
  Object.keys(events).forEach(k => {
    tag.addEventListener(k, events[k]);
  });
  document.head.appendChild(tag);
};

export const loadScript = (src, properties, events) => {
  addHeadTag(
    'script',
    {
      src,
      type: 'text/javascript',
      ...properties,
    },
    events
  );
};

export const loadStylesheet = (src, properties, events) =>
  addHeadTag(
    'link',
    {
      href: src,
      type: 'text/css',
      rel: 'stylesheet',
      ...properties,
    },
    events
  );
