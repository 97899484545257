import { COUNTRY_GROUPS } from 'services/constants';

const recurlyMap = {
  [COUNTRY_GROUPS.NONUS]: process.env.RECURLY_NONUS,
  [COUNTRY_GROUPS.US]: process.env.RECURLY_US,
};

/**
 * @param {number} countryGroup One of `COUNTRY_GROUPS`
 */
export function getRecurlyId(countryGroup) {
  return recurlyMap[countryGroup];
}
