import { createServerResource } from 'services/server-resource';

import * as parsers from './parsers';

const apiUrl = process.env.apiUrl;

export const registerUserMetadata = createServerResource(
  'post',
  `${apiUrl}/user/register-metadata`,
  {
    inputMap: {
      userId: 'userId',
      provider: 'provider',
      metadata: 'metadata',
    },
    parsers: [parsers.errorParser],
  }
);
