import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useAuthentication } from 'hooks/authentication';

/**
 * Checks if user has the provided permissions.
 * The permissions parameter can be either string or an array of strings (if multiple permissions to check).
 * A secondary parameter (allRequired) can be specified to determine whether all permissions in the
 * provided array need to be applicable to the user or only some of them.
 *
 * @param {string|string[]} permissions
 * @param {boolean} [allRequired=true]
 * @returns boolean
 */
export const usePermission = (permissions, allRequired = true) => {
  const authentication = useAuthentication();

  if (Array.isArray(permissions)) {
    const method = allRequired ? 'every' : 'some';
    return permissions[method](permission =>
      authentication.hasPermission(permission)
    );
  }
  return authentication.hasPermission(permissions);
};

export function useSetHeadTitle(title) {
  useEffect(
    function setHeadTitle() {
      document.title = title ? `${title} | ScyllaDB Cloud` : 'ScyllaDB Cloud';
    },
    [title]
  );
}

export const useNavigateWithSearch = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  return to => {
    const toPathname = to.split('?')[0];
    const toSearchParams = new URLSearchParams(to.split('?')[1]);
    const mergedSearchParams = new URLSearchParams();

    for (const [key, value] of searchParams.entries()) {
      mergedSearchParams.set(key, value);
    }

    for (const [key, value] of toSearchParams.entries()) {
      mergedSearchParams.set(key, value);
    }

    return navigate({
      pathname: toPathname,
      search: mergedSearchParams.toString(),
    });
  };
};
