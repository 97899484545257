import * as colors from './colors';
import * as fonts from './fonts';

export const baseTable = {
  width: '100%',
  borderCollapse: 'separate',
  border: `1px solid ${colors.mystic}`,
  borderRadius: '6px',
  backgroundColor: colors.white,
  overflow: 'hidden',
  padding: 0,
  '& thead > tr': {
    ...fonts.column,
    color: colors.periwinkleGray,
    textTransform: 'capitalize',
    height: 'auto',
    '& > th': {
      borderBottom: `1px solid ${colors.mystic}`,
    },
  },
  '& tbody > tr': {
    height: 'auto',
    '& > td': {
      ...fonts.sectionTitle,
      color: colors.baliHai,
      border: 0,
    },
  },
  '& th, & td': {
    textAlign: 'center',
    padding: '14px 0',
    whiteSpace: 'nowrap',
  },
  '& th:first-of-type, & td:first-of-type': {
    paddingLeft: '30px',
  },
};

export const hoverable = {
  '& tbody > tr': {
    '&:hover': {
      backgroundColor: colors.pictonBlue,
      '& > td': {
        color: colors.white,
      },
    },
    '& > td': {
      cursor: 'pointer',
    },
  },
};
