import * as parsers from 'services/resources/parsers';
import { createServerResource } from 'services/server-resource';

const apiUrl = process.env.apiUrl;

export const createVPCPeering = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/network/vpc/peer`,
  {
    inputMap: {
      vpcId: 'vpcId',
      cidrBlock: 'cidrBlock',
      ownerId: 'ownerId',
      regionId: 'regionId',
      allowCql: 'allowCql',
      dcId: 'dcId',
    },
    queryParamsMap: {
      async: 'async',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const deleteVPCPeering = createServerResource(
  'delete',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/network/vpc/peer/{{peerId}}`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);
