import { Box } from '@mui/material';

import StorageOptionSelect from 'components/pages/new-cluster/ClusterProperties/InstanceSelection/TableCells/StorageOptionSelect';
import withStyles from 'components/withStylesAdapter';
import { formatMemory, formatNetworkSpeed } from 'services/cluster';

const withJss = withStyles(theme => ({
  root: {
    color: `${theme.colors.black}60`,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    paddingTop: '0.5rem',
  },
  oval: {
    fontSize: '1.5rem',
    position: 'relative',
    top: theme.typography.pxToRem(5),
  },
}));

function InstanceSummary({ instance, group, onStorageOptionChange, classes }) {
  function onStorageChange(changeType, groupExternalId, selectedVariantId) {
    onStorageOptionChange(selectedVariantId);
  }
  const storage =
    group?.length > 1 ? (
      <StorageOptionSelect
        groupInstances={group}
        groupExternalId={group[0]?.instance?.externalId}
        selectedVariant={instance}
        handleSelectionChange={onStorageChange}
        showBorder
        storageLabel="Storage"
      />
    ) : (
      `${instance?.instance.totalStorage} GB Storage`
    );

  return (
    <Box className={classes.root}>
      {instance?.instance.cpuCount} vCPU{' '}
      <span className={classes.oval}> • </span> {storage}{' '}
      <span className={classes.oval}> • </span>{' '}
      {formatMemory(instance?.instance.memory)} Memory{' '}
      <span className={classes.oval}> • </span>{' '}
      {formatNetworkSpeed(instance?.instance.networkSpeed)} Gbps Network
    </Box>
  );
}

export default withJss(InstanceSummary);
