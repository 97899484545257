import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  numberOfNodesTooltip: {
    margin: `0 ${theme.spacing(1)}`,
  },
  tooltipIcon: {
    width: '1.5rem',
    color: theme.colors.actionActive,
  },
}));
function HeaderTooltip({ classes, title, content }) {
  return (
    <>
      <span>{title}</span>
      <Tooltip
        className={classes.numberOfNodesTooltip}
        title={content}
        placement="top"
        arrow
      >
        <InfoOutlined className={classes.tooltipIcon} />
      </Tooltip>
    </>
  );
}

export default withJss(HeaderTooltip);
