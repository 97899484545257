import { mutate } from 'swr';

import { useDefaultAccountId } from 'hooks/account';
import { useMutationRequest, useQueryRequest } from 'hooks/fetch';
import { setAccountBillingInfo } from 'services/resources/account/account';

const apiUrl = process.env.apiUrl;

const accountBillingInfoKey = (accountId, withCard = false) =>
  accountId &&
  `${apiUrl}/account/${accountId}/billing/info${
    withCard ? '?with_card=true' : ''
  }`;

export function useAccountBillingInfo({ withCard = false } = {}) {
  const defaultAccountId = useDefaultAccountId();
  const key = accountBillingInfoKey(defaultAccountId, withCard);

  return useQueryRequest({ key, withToken: true });
}

export function useSetBillingInfoRequest() {
  const accountId = useDefaultAccountId();

  const { request, isLoading, error } = useMutationRequest({
    requester: (payload, token) =>
      setAccountBillingInfo.call({
        ...payload,
        accountId,
        token,
      }),
    onSuccess: () => {
      mutate(accountBillingInfoKey(accountId));
    },
    onFailure: err => {
      throw new Error(err);
    },
  });

  return {
    request,
    isLoading,
    error,
  };
}
