import { nanoid } from 'nanoid';

export const createDbaasResourceMiddleware =
  next =>
  (...args) => {
    const {
      shouldUseTokenHeader = true,
      dbaasTokenPayloadKey = '__dbaasToken__',
      traceIdKey = '__externalTraceId__',
      ...resourceOptions
    } = args[2] || {};

    resourceOptions.headersMap = {
      [traceIdKey]: 'Trace-Id',
      ...resourceOptions.headersMap,
    };

    // add token header
    if (shouldUseTokenHeader) {
      resourceOptions.headersMap = {
        [dbaasTokenPayloadKey]: 'Authorization',
        ...resourceOptions.headersMap,
      };
    }

    // create the resource
    const dbaasResource = next(...args.slice(0, 2), resourceOptions);

    // override the resource call method to automatically add the header token
    const resourceCallMethod = dbaasResource.call;
    dbaasResource.call = (...callArgs) => {
      const payload = { ...(callArgs[0] || {}) };
      if (shouldUseTokenHeader) {
        const dbaasToken = `Bearer ${payload.token}`;

        if (dbaasToken) {
          payload[dbaasTokenPayloadKey] = dbaasToken;
        }
      }
      if (payload[traceIdKey] === undefined) {
        payload[traceIdKey] = nanoid(process.env.uIdLength);
      }
      return resourceCallMethod(payload, ...callArgs.slice(1));
    };

    return dbaasResource;
  };
