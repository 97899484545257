import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { isFunction } from 'lodash/fp';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { dataDogWorkerUrl } from './worker-loader';

function isBlacklisted(href) {
  return Boolean(href.includes('token='));
}

function sanitizeUrl(href) {
  const url = new URL(href);

  const searchParams = new URLSearchParams(url.search);
  searchParams.delete('token');

  const strippedSearchString = `?${searchParams.toString()}`;
  url.search = strippedSearchString;

  return url.toString();
}

const ReactGAProxy = new Proxy(ReactGA, {
  get(target, prop) {
    const href = window.location.href;

    if (isFunction(target[prop]) && isBlacklisted(href)) {
      return function proxiedGaFn(...args) {
        target.set({ location: sanitizeUrl(href) });

        return target[prop].apply(this, args);
      };
    }

    return target[prop];
  },
});

function loadHeap(id) {
  let heap = (window.heap = window.heap || []);
  heap.load = function (e, t = {}) {
    window.heap.appid = e;
    window.heap.config = t;
    var r = document.createElement('script');
    r.type = 'text/javascript';
    r.async = !0;
    r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
    var a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(r, a);
    for (
      var n = function (e) {
          return function () {
            heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        },
        p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
        ],
        o = 0;
      o < p.length;
      o++
    )
      heap[p[o]] = n(p[o]);
  };
  heap.load(id);
}

export function trackHeapEvent(eventName, properties) {
  if (process.env.heapAppId && window.heap) {
    window.heap.track(eventName, properties);
  }
}

function loadDataDog() {
  datadogRum.init({
    applicationId: process.env.DataDogApplicationId,
    clientToken: process.env.DataDogClientToken,
    site: 'datadoghq.com',
    service: 'scylla-cloud-client',
    env: process.env.REACT_APP_DEST,
    version: `${process.env.REACT_APP_DEST}-${process.env.REACT_APP_BUILD_COMMIT}`,
    sessionSampleRate: process.env.DataDogSessionSampleRate,
    sessionReplaySampleRate: process.env.DataDogReplaySampleRate,
    traceSampleRate: process.env.DataDogTraceSampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      `${window.location.protocol}//${window.location.host}${process.env.apiUrl}`,
    ],
    workerUrl: dataDogWorkerUrl,
    beforeSend: function collectTraceId(event, context) {
      if (event.type === 'resource' && event.resource.type === 'xhr') {
        event.context.traceId = context.xhr.getResponseHeader('trace-id');
      }
      return true;
    },
  });

  datadogLogs.init({
    applicationId: process.env.DataDogApplicationId,
    clientToken: process.env.DataDogClientToken,
    site: 'datadoghq.com',
    service: 'scylla-cloud-client',
    env: process.env.REACT_APP_DEST,
    version: `${process.env.REACT_APP_DEST}-${process.env.REACT_APP_BUILD_COMMIT}`,
    forwardErrorsToLogs: false,
    sessionSampleRate: process.env.DataDogSessionSampleRate,
  });
}

function initAnalyticsServices() {
  // setting google analytics
  const isProd = process.env.NODE_ENV === 'production';

  process.env.googleAnalyticsKey &&
    ReactGAProxy.initialize(process.env.googleAnalyticsKey, {
      testMode: !isProd,
      debug: false,
    });
  process.env.DataDogApplicationId &&
    process.env.DataDogClientToken &&
    loadDataDog();

  process.env.heapAppId && loadHeap(process.env.heapAppId);
}

function setAnalyticsUser(profile = {}) {
  const userId = profile?.id?.toString();
  ReactGAProxy.set({ user_id: userId });

  const { email, companyName, name, defaultAccountId } = profile;
  const userProperties = {
    email,
    displayName: name,
    companyName,
    accountId: defaultAccountId,
  };

  if (process.env.heapAppId) {
    const heap = window.heap;
    heap.identify(userId);
    heap.addUserProperties(userProperties);
  }
  if (process.env.DataDogApplicationId && process.env.DataDogClientToken) {
    datadogRum.setUser({ id: userId, ...userProperties });
  }
}

function traverseChildren(node) {
  if (node.name === 'password') {
    return node.setAttribute('data-dd-privacy', 'hidden');
  }

  if (node.childNodes.length > 0) {
    node.childNodes.forEach(childNode => {
      traverseChildren(childNode);
    });
  }
}

function observePasswordFields(records) {
  records.forEach(record => {
    const addedNodes = record?.addedNodes;

    addedNodes?.forEach(node => {
      traverseChildren(node);
    });
  });
}

function useMaskPasswordFields(root) {
  useEffect(
    function startObservePasswordFields() {
      if (root) {
        const observer = new MutationObserver(observePasswordFields);

        observer.observe(root, {
          childList: true,
          subtree: true,
        });
        return () => {
          observer.disconnect();
        };
      }
    },
    [root]
  );
}

export {
  ReactGAProxy as GoogleAnalytics,
  initAnalyticsServices,
  setAnalyticsUser,
  useMaskPasswordFields,
};
