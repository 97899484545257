import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import AlertWithTraceId from 'components/common/alert-with-trace-id';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import { createMuiRegister } from 'components/pages/new-cluster/hooks/form';
import withStyles from 'components/withStylesAdapter';
import { useDeleteCluster } from 'hooks/cluster';
import ERRORS, { TYPES } from 'services/translations/field-validations';
import { SUCCESS } from 'services/translations/notifications';

const withJss = withStyles(theme => ({
  caption: {
    fontSize: theme.typography.pxToRem(14),
    margin: '0 0 10px',
  },
  notification: {
    fontSize: theme.typography.pxToRem(11),
    margin: '30px 0 10px',
  },
  input: {
    ...theme.extensions.borderedInput,
  },
  alert: {
    margin: '20px 0',
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  deleteButton: {
    ...theme.extensions.customButton(),
  },
}));

function DeleteClusterModalComponent({
  hideModal,
  classes,
  params: { clusterId, clusterName },
}) {
  const { request, isLoading, error, data } = useDeleteCluster(clusterId);
  const hookForm = useForm({ mode: 'onChange' });
  const muiRegister = createMuiRegister(hookForm, {});
  const validateName = useCallback(
    value => value === clusterName,
    [clusterName]
  );
  const isSuccess = Boolean(data);

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <form onSubmit={hookForm.handleSubmit(() => request())}>
        <MainModalHeader onClose={hideModal}>DELETE CLUSTER</MainModalHeader>

        <MainModalContent>
          <AlertWithTraceId
            isSuccess={isSuccess}
            successTitle={SUCCESS.CLUSTER.DELETE}
            error={error}
            className={classes.alert}
            id="deleteCluster"
          />

          {!isSuccess && (
            <>
              <Typography
                variant="caption"
                color="error"
                display="block"
                className={classes.caption}
              >
                Deleting a cluster is irreversible!
              </Typography>
              <Typography
                variant="caption"
                color="error"
                display="block"
                className={classes.caption}
              >
                All data will be lost including all cluster backups.
              </Typography>
              <Typography
                variant="caption"
                display="block"
                className={classes.notification}
              >
                Enter the name of the cluster you wish to delete.
              </Typography>
              <TextField
                {...muiRegister({
                  name: 'clusterName',
                  defaultValue: '',
                  useErrorText: true,
                  required: {
                    value: true,
                    message: ERRORS[TYPES.CLUSTERNAME].BLANK,
                  },
                  validate: validateName,
                })}
                autoFocus={true}
                id="clusterNameInp"
                placeholder="Cluster name *"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.input,
                  },
                }}
                fullWidth
              />
            </>
          )}
        </MainModalContent>

        <MainModalActions>
          {isSuccess ? (
            <MainModalActionButton
              id="goBackBtn"
              type={MainModalActionButton.Types.MAJOR}
              onClick={hideModal}
              fullWidth
            >
              Go back to My Clusters
            </MainModalActionButton>
          ) : (
            <div className={classes.buttonsContainer}>
              <MainModalActionButton
                id="deleteClusterBtn"
                /*
                  To prevent syntetic event from being passed to the request function,
                  we have to use an arrow function here, otherwise the event will be passed as an argument
                  and token will not be passed to the requester
                */
                onClick={() => request()}
                type={MainModalActionButton.Types.MAJOR}
                isLoading={isLoading}
                disabled={!hookForm?.formState?.isValid}
                className={classes.deleteButton}
              >
                DELETE
              </MainModalActionButton>
              <MainModalActionButton
                id="deleteClusterCancelBtn"
                type={MainModalActionButton.Types.MINOR}
                onClick={hideModal}
              >
                CANCEL
              </MainModalActionButton>
            </div>
          )}
        </MainModalActions>
      </form>
    </MainModal>
  );
}
DeleteClusterModalComponent.propTypes = {
  params: PropTypes.shape({
    clusterName: PropTypes.string,
    clusterId: PropTypes.string,
  }),
  classes: PropTypes.shape({}),
  hideModal: PropTypes.func,
};

export default withJss(DeleteClusterModalComponent);
