import { datadogRum } from '@datadog/browser-rum';
import { Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import ScyllaWorker from 'assets/images/scylla-worker.svg?react';
import withStyles from 'components/withStylesAdapter';
import { GoogleAnalytics } from 'utils/analytics';

const withJss = withStyles(theme => ({
  logo: {
    width: theme.typography.pxToRem(144),
    marginLeft: '3em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colors.darkSanMarino,
  },
  description: {
    color: theme.colors.darkSanMarino,
    textAlign: 'center',
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.colors.darkSanMarino,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(42),
    fontWeight: 500,
  },
}));

function ScyllaErrorComponent({
  classes,
  error,
  resetErrorBoundary,
  buttonText,
}) {
  const defaultErrorMsg = (
    <>
      Something happened <br />
      We will investigate and fix it ASAP
    </>
  );

  return (
    <div className={classes.container}>
      <ScyllaWorker className={classes.logo} />
      <Typography variant="h4" className={classes.title} paragraph>
        Oops!
      </Typography>
      <Typography variant="subtitle1" className={classes.description} paragraph>
        {error?.msg ? error.msg : defaultErrorMsg}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={error?.action ? error.action : resetErrorBoundary}
      >
        {error?.btnText ? error.btnText : buttonText}
      </Button>
    </div>
  );
}

ScyllaErrorComponent.propTypes = {
  classes: PropTypes.shape({}),
  error: PropTypes.shape({
    msg: PropTypes.string,
    btnText: PropTypes.string,
    action: PropTypes.func,
  }),
  resetErrorBoundary: PropTypes.func,
  buttonText: PropTypes.string,
};

const ScyllaError = withJss(ScyllaErrorComponent);

// ErrorBoundary with defaults from our app
function ErrorBoundary({
  FallbackComponent,
  children,
  onReset = () => (window.location.href = '/clusters/list'),
  onError = (error, info) => {
    console.log(error, info);
    GoogleAnalytics.event({
      category: 'UI Error',
      action: error.toString(),
      label: JSON.stringify(info),
    });

    if (process.env.DataDogApplicationId && process.env.DataDogClientToken) {
      const renderingError = new Error(error.message);
      renderingError.name = `ReactRenderingError`;
      renderingError.stack = info.componentStack;
      renderingError.cause = error;

      datadogRum.addError(renderingError);
    }
  },
  resetKeys,
  onResetKeysChange,
  buttonText = 'BACK TO CLUSTER PAGE',
}) {
  return (
    <ReactErrorBoundary
      fallbackRender={
        !FallbackComponent &&
        (fallBackProps => (
          <ScyllaError buttonText={buttonText} {...fallBackProps} />
        ))
      }
      FallbackComponent={FallbackComponent}
      onReset={onReset}
      onError={onError}
      resetKeys={resetKeys}
      onResetKeysChange={onResetKeysChange}
    >
      {children}
    </ReactErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  FallbackComponent: PropTypes.node,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  resetKeys: PropTypes.array,
  onResetKeysChange: PropTypes.func,
  buttonText: PropTypes.string,
};

export * from 'react-error-boundary';

export { ScyllaError, ErrorBoundary };
