import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { uniq } from 'lodash/fp';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';
import { byHours, byWeekDays } from 'services/maintenance-windows';

const withJss = withStyles(theme => ({
  selectItem: {
    minWidth: theme.typography.pxToRem(220),
    marginRight: theme.typography.pxToRem(16),
  },
  windowItem: {
    display: 'flex',
    margin: `${theme.typography.pxToRem(20)} 0`,
  },
}));

const windowHourIdPrefix = window => `window-hour-${window.id}`;
const windowDayIdPrefix = window => `window-day-${window.id}`;

function MaintenanceWindowItem({
  classes,
  window,
  children,
  onChange,
  options,
}) {
  const availableDaysOptions = options.concat(window?.day ? window : []);
  const daysOptions = availableDaysOptions?.map(o => o?.day);
  const uniqSortedDayOptions = uniq(daysOptions).sort(byWeekDays);

  const availableHoursOptions = window?.day
    ? options
        ?.filter(option => option?.day === window?.day)
        .concat(window?.hours ? window : [])
    : options;
  const hoursOptions = availableHoursOptions?.map(option => option.hours);
  const uniqSortedHoursOptions = uniq(hoursOptions).sort(byHours);

  return (
    <div
      data-testid="MaintenanceWindowItem-root"
      className={classes.windowItem}
    >
      <FormControl className={classes.selectItem} size="small">
        <InputLabel id={`${windowDayIdPrefix(window)}-label`}>Day</InputLabel>
        <Select
          labelId={`${windowDayIdPrefix(window)}-label`}
          id={windowDayIdPrefix(window)}
          data-testid={windowDayIdPrefix(window)}
          label="Day"
          value={window.day}
          onChange={e => onChange({ ...window, day: e.target.value })}
          displayEmpty
        >
          {uniqSortedDayOptions?.map(day => (
            <MenuItem value={day} key={day} id={`maintenance-day-${day}`}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.selectItem} size="small">
        <InputLabel
          htmlFor={windowHourIdPrefix(window)}
          id={`${windowHourIdPrefix(window)}-label`}
        >
          Hour
        </InputLabel>
        <Select
          label="Hour"
          placeholder="Hour"
          labelId={`${windowHourIdPrefix(window)}-label`}
          id={windowHourIdPrefix(window)}
          data-testid={windowHourIdPrefix(window)}
          value={window.hours}
          onChange={e =>
            onChange({
              ...window,
              hours: e.target.value,
            })
          }
          displayEmpty
        >
          {uniqSortedHoursOptions?.map(hours => (
            <MenuItem
              value={hours}
              key={hours}
              id={`maintenance-hours-${hours}`}
            >
              {hours}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {children}
    </div>
  );
}

MaintenanceWindowItem.propTypes = {
  classes: PropTypes.shape({}),
  window: PropTypes.shape({}),
  children: PropTypes.node,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      hours: PropTypes.string,
      rrule: PropTypes.string,
      duration: PropTypes.number,
    })
  ),
};

export default withJss(MaintenanceWindowItem);
