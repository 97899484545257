import { FormControl, Select, MenuItem } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { INSTANCE_TABLE_CHANGE_TYPE } from 'components/pages/new-cluster/ClusterProperties/InstanceSelection/instance-selection';
import withStyles from 'components/withStylesAdapter';
import { formatStorage } from 'services/cluster';

const withJss = withStyles(theme => ({
  storageSelect: {
    '.MuiInput-input': {
      color: '#404451',
      fontSize: '0.8125rem',
      '&:focus': {
        backgroundColor: 'unset',
      },
    },
  },
  noBorder: {
    '&::before, &:hover:not(.Mui-disabled)::before, &::after': {
      borderBottom: 'unset',
    },
  },
  colored: {
    '.MuiInput-input': {
      color: theme.colors.sanMarino,
    },
    color: theme.colors.sanMarino,
  },
}));

function byStorageAsc(instanceA, instanceB) {
  return instanceA.totalStorage - instanceB.totalStorage;
}

function StorageOptionSelect({
  selectedVariant,
  handleSelectionChange,
  groupExternalId,
  storageLabel,
  showBorder,
  classes,
  groupInstances,
  colored,
}) {
  function onStorageOptionChange(ev) {
    const selectedInstanceVariant = groupInstances.find(
      i => i.instance.totalStorage === ev.target.value
    );

    handleSelectionChange(
      INSTANCE_TABLE_CHANGE_TYPE.VARIANT,
      groupExternalId,
      selectedInstanceVariant.id
    );
  }

  return (
    <FormControl variant="standard" data-testid="StorageOptionSelect-root">
      <Select
        SelectDisplayProps={{
          id: `${groupExternalId}StorageOptions`,
          'data-testid': `StorageOptionSelect`,
        }}
        inputProps={{
          id: `${groupExternalId}StorageInput`,
          classes: {
            icon: classNames({
              [classes.colored]: colored,
            }),
          },
        }}
        MenuProps={{
          id: `${groupExternalId}StorageList`,
        }}
        className={classNames(classes.storageSelect, {
          [classes.noBorder]: !showBorder,
          [classes.colored]: colored,
        })}
        value={selectedVariant.instance.totalStorage}
        onChange={onStorageOptionChange}
        onClick={ev => {
          ev.stopPropagation();
        }}
        variant="standard"
      >
        {groupInstances
          .map(({ instance }) => instance)
          .sort(byStorageAsc)
          .map(i => (
            <MenuItem
              key={i.totalStorage}
              value={i.totalStorage}
              id={`storage-option-${i.totalStorage}`}
            >
              {`${formatStorage(i.totalStorage)} ${storageLabel ?? ''}`}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default withJss(StorageOptionSelect);

StorageOptionSelect.propTypes = {
  instances: PropTypes.arrayOf(PropTypes.object),
  selectedVariant: PropTypes.object,
  handleSelectionChange: PropTypes.func,
  groupExternalId: PropTypes.string,
  storageLabel: PropTypes.string,
  showBorder: PropTypes.bool,
  colored: PropTypes.bool,
};
