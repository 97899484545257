import { Button } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from 'components/common/spinner';
import withStyles from 'components/withStylesAdapter';

// when isLoading is true - adding extra space to the edge except fullWidth and large buttons
const shouldAddSpinnerPadding = ({ isLoading, fullWidth, size } = {}) =>
  isLoading && !fullWidth && size !== 'large';

const withJss = withStyles((theme, props) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingRight: shouldAddSpinnerPadding(props) ? '32px' : null,
  },
  spinner: {
    ...theme.extensions.spinner,
  },
}));

function LoadingButton({
  isLoading = false,
  children,
  className,
  classes,
  ...buttonProps
}) {
  return (
    <Button
      className={classnames(className, classes.button)}
      {...buttonProps}
      data-loading={isLoading}
    >
      {children}
      {isLoading && <Spinner className={classes.spinner} />}
    </Button>
  );
}

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.shape({}),
};

export default withJss(LoadingButton);
