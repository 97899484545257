export const hoursPerMonth = 730;

export const CALCULATOR_MODE = {
  DETAILS: 'DETAILS',
  ASTRA: 'ASTRA',
  DYNAMO: 'DYNAMO',
};

export const competitorNames = {
  [CALCULATOR_MODE.ASTRA]: 'Datastax Astra',
  [CALCULATOR_MODE.DYNAMO]: 'DynamoDB',
};

export function toLocalePrice(price) {
  return price.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
}

export function transformToScaledValue(val) {
  const scaledValue = 10 ** val;
  const roundOffTo = Math.max(
    10 ** (Math.floor(Math.log10(scaledValue)) - 1),
    1
  );
  return Math.round(scaledValue / roundOffTo) * roundOffTo;
}
