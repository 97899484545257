import { clusterValidators } from 'services/validators';

import ChipsInput from './ChipsInput';

const cidrListName = 'cidrList';

function CidrInput({ form }) {
  return (
    <ChipsInput
      form={form}
      label="AWS VPC CIDRs (for cluster to transit gateway traffic)"
      listName={cidrListName}
      required={{
        value: clusterValidators.tgwCidrList.required.value,
        message: clusterValidators.tgwCidrList.required.message,
      }}
      inputValidationRules={{
        pattern: {
          value: clusterValidators.tgwCidrInput.pattern.value,
          message: clusterValidators.tgwCidrInput.pattern.message,
        },
      }}
      listValidationRules={{
        validate: list =>
          clusterValidators.tgwCidrList.validate(list) ||
          clusterValidators.tgwCidrList.validateMessage,
      }}
    />
  );
}

export default CidrInput;
