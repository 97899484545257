import {
  Help as HelpIcon,
  Support as SupportIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Spinner from 'components/common/spinner';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';
import { useSupportRedirect } from 'utils/hooks';

const withJss = withStyles(theme => ({
  disabledItem: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.colors.sonicSilver,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    '&.Mui-disabled': {
      opacity: 'unset',
    },
  },
  loadingItem: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
  menuIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: '1.6rem',
      fontSize: '1.1rem',
    },
  },
}));

function RecourcesLinks({ classes = {} }) {
  const { redirectToSupport, isLoading: isSupportLoading } =
    useSupportRedirect();

  return (
    <>
      <MenuItem className={classes.disabledItem} disabled>
        Resources
      </MenuItem>
      <MenuItem className={classes.menuItem}>
        <Link
          className={classes.link}
          to={process.env.scyllaCloudDocsUrl}
          target="_blank"
        >
          <ListItemIcon className={classes.menuIcon}>
            <HelpIcon fontSize="inherit" />
          </ListItemIcon>
          Documentation
        </Link>
      </MenuItem>
      <MenuItem
        href="/support"
        target="_blank"
        className={classNames(classes.menuItem, {
          [classes.loadingItem]: isSupportLoading,
        })}
        onClick={redirectToSupport}
      >
        <ListItemIcon className={classes.menuIcon}>
          {isSupportLoading ? <Spinner /> : <SupportIcon fontSize="inherit" />}
        </ListItemIcon>
        Support
      </MenuItem>

      <MenuItem className={classes.menuItem}>
        <Link
          className={classes.link}
          to={documentationLinks.universityResources.cloudLandingPage}
          target="_blank"
        >
          <ListItemIcon className={classes.menuIcon}>
            <SchoolIcon fontSize="inherit" />
          </ListItemIcon>
          University
        </Link>
      </MenuItem>
    </>
  );
}

export default withJss(RecourcesLinks);
