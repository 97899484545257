import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AlertWithTraceId from 'components/common/alert-with-trace-id';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { useOwnCloudAccount } from 'hooks/account';
import { useCreateCloudAccount, useDeleteCloudAccount } from 'hooks/byoa';

const withJss = withStyles(theme => ({
  caption: {
    margin: '10px 0 0',
  },
  alert: {
    margin: '20px 0',
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
}));

function RestartCloudAccount({ classes, hideModal }) {
  const navigate = useNavigate();

  const {
    data: { id: cloudAccountId } = {},
    isLoading: isOwnAccountLoading,
    error: ownAccountError,
  } = useOwnCloudAccount();
  const {
    request: createCloudAccount,
    isLoading: isCreateLoading,
    error: createError,
  } = useCreateCloudAccount();
  const {
    request: deleteCloudAccount,
    isLoading: isDeleteLoading,
    error: deleteError,
  } = useDeleteCloudAccount();

  const handleRestart = async () => {
    if (cloudAccountId) {
      await deleteCloudAccount(cloudAccountId);
    }
    await createCloudAccount();

    navigate('/managed-resources/cloud-account-setup');
    hideModal();
  };

  const close = () => {
    hideModal();
  };

  const isLoading = isCreateLoading || isDeleteLoading || isOwnAccountLoading;
  const error = createError || deleteError || ownAccountError;

  return (
    <MainModal onClose={close} fullWidth maxWidth="sm">
      <MainModalHeader onClose={close}>RESTART THE WIZARD</MainModalHeader>
      <MainModalContent>
        <AlertWithTraceId
          error={error}
          className={classes.alert}
          id="restartWizardAlert"
        />
        <Typography variant="caption" paragraph>
          Restarting the Cloud Account setup wizard will delete all the data
          entered so far and let you start from scratch. Please confirm the
          restart.
        </Typography>
      </MainModalContent>
      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="restartCloudAccountBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={handleRestart}
            isLoading={isLoading}
          >
            RESTART
          </MainModalActionButton>
          <MainModalActionButton
            id="restartCloudAccountCancelBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={close}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

RestartCloudAccount.propTypes = {
  classes: PropTypes.shape({}),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(RestartCloudAccount);
