import PropTypes from 'prop-types';

import Alert from 'components/common/alert';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { SUCCESS } from 'services/translations/notifications';
import { useSupportRedirect } from 'utils/hooks';

const withJss = withStyles({
  alert: {
    marginBottom: 30,
  },
});

function AddDCSuccessModalComponent({ classes, hideModal }) {
  const { redirectToSupport, isLoading: isSupportLoading } =
    useSupportRedirect();

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader onClose={hideModal}>ADD DATA CENTER</MainModalHeader>

      <MainModalContent>
        <Alert
          visible
          id="addDcSuccess"
          title={SUCCESS.CLUSTER.ADD_DC({
            onSupportClick: redirectToSupport,
            isSupportLoading,
          })}
          status="success"
          className={classes.alert}
        />
      </MainModalContent>

      <MainModalActions>
        <MainModalActionButton
          id="goBackBtn"
          type={MainModalActionButton.Types.MAJOR}
          onClick={hideModal}
          fullWidth
        >
          Go back to My Cluster
        </MainModalActionButton>
      </MainModalActions>
    </MainModal>
  );
}

AddDCSuccessModalComponent.propTypes = {
  classes: PropTypes.shape({}),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(AddDCSuccessModalComponent);
