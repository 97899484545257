import HeaderTooltip from 'components/pages/new-cluster/ClusterProperties/InstanceSelection/Tooltips/HeaderTooltip';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';

const withJss = withStyles(theme => ({
  numberOfNodesTooltip: {
    margin: `0 ${theme.spacing(1)}`,
  },
  numberOfNodesTooltipContent: {
    margin: theme.spacing(1),
  },
  link: {
    color: theme.colors.pictonBlue,
  },
}));

function StorageHeaderTooltip({ classes = {} }) {
  return (
    <HeaderTooltip
      title="Storage (NVMe)"
      content={
        <div className={classes.numberOfNodesTooltipContent}>
          <p>Raw storage for single node.</p>
          <p>
            See{' '}
            <a
              className={classes.link}
              href={
                documentationLinks.enterpriseDocs.gettingStarted
                  .systemRequirementsDiskSpace
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            for guidelines on actual cluster storage after replication.
          </p>
        </div>
      }
    />
  );
}

export default withJss(StorageHeaderTooltip);
