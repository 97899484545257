import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import LoadingPage from 'components/common/LoadingPage';
import { usePermission, useSetHeadTitle } from 'hooks/utils';

import AppLayout from './app-layout';

export default function AppRoute({
  component: Component,
  subheader: Subheader,
  provider: Provider,
  permission,
  title = 'Scylla Cloud',
}) {
  const canUserAccessRoute = usePermission(permission, false);

  useSetHeadTitle(title);

  if (permission && !canUserAccessRoute) {
    return <Navigate to="/" />;
  }

  return Provider ? (
    <Suspense fallback={<LoadingPage />}>
      <Provider>
        <AppLayout
          renderSubheader={Subheader ? () => <Subheader /> : null}
          renderContent={() => <Component />}
        />
      </Provider>
    </Suspense>
  ) : (
    <AppLayout
      renderSubheader={() => (
        <Suspense fallback={null}>{Subheader && <Subheader />}</Suspense>
      )}
      renderContent={() => (
        <Suspense fallback={null}>
          <Component />
        </Suspense>
      )}
    />
  );
}

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  subheader: PropTypes.elementType,
  provider: PropTypes.elementType,
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
};
