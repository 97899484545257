export const SELF_SERVICE_PROCESSOR = {
  SUPPORT: 'support',
  AUTOMATION: 'automation',
};

export function extractManualResizeRequest(resizeRequests) {
  return resizeRequests?.find(req => {
    try {
      return (
        JSON.parse(req.requestBody).processor === SELF_SERVICE_PROCESSOR.SUPPORT
      );
    } catch {
      return undefined;
    }
  });
}
