import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import MainModal, {
  MainModalContent,
  MainModalHeader,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import {
  CLIENT_TO_NODE_ENCRYPTION_MODES,
  CLIENT_TO_NODE_ENCRYPTION_MODE,
} from 'services/constants';

const withJss = withStyles(theme => ({
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  link: theme.extensions.link,
  cloudIcon: {
    marginRight: theme.spacing(1),
  },
  downloadBtn: {
    whiteSpace: 'nowrap',
  },
}));

function ClusterCtnEncryptionModeModal({
  hideModal,
  classes,
  params: { currentMode, clusterName, onSubmit },
}) {
  const theme = useTheme();
  const toBeSecured = currentMode === CLIENT_TO_NODE_ENCRYPTION_MODE.BOTH;

  const submitModeChange = () => {
    onSubmit();
    hideModal();
  };

  return (
    <MainModal onClose={hideModal} maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={
          toBeSecured ? (
            <LockOutlinedIcon fontSize="medium" />
          ) : (
            <LockOpenOutlinedIcon fontSize="medium" />
          )
        }
        helperIconColor={
          toBeSecured ? theme.colors.infoGreen : theme.colors.adamantineBlue
        }
      >
        {toBeSecured
          ? 'Secure All Client Connections'
          : 'Allow Non-Encrypted Client Connections'}
      </MainModalHeader>
      <MainModalContent>
        {toBeSecured ? (
          <>
            <Typography paragraph>
              You selected to secure all client connections. This will terminate
              all non-TLS connections to the cluster&nbsp;<b>{clusterName}</b>.
            </Typography>
            <Typography paragraph>
              While the service will not be interrupted, you may experience
              slight performance impacts during the required rolling restart.
              The rolling restart process is designed to minimize disruption,
              ensuring a smooth transition. However, some impact on service
              responsiveness may occur.
            </Typography>
          </>
        ) : (
          <>
            <Typography paragraph>
              You selected to allow non-encrypted client connections. This
              operation will enable non-secured connections to the cluster&nbsp;
              <b>{clusterName}</b>.
            </Typography>
            <Typography paragraph>
              While the service will not be interrupted, you may experience
              slight performance impacts during the required rolling restart.
              The rolling restart process is designed to minimize disruption,
              but some impact on service responsiveness may occur.
            </Typography>
          </>
        )}
      </MainModalContent>

      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="changeCtnEncryptionBtn"
            data-testid={
              toBeSecured
                ? 'enable-ctn-encryption-modal-btn'
                : 'disable-ctn-encryption-modal-btn'
            }
            type={MainModalActionButton.Types.MAJOR}
            onClick={submitModeChange}
            className={classes.downloadBtn}
            fullWidth
          >
            PROCEED
          </MainModalActionButton>
          <MainModalActionButton
            id="cancelModalBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

ClusterCtnEncryptionModeModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    currentMode: PropTypes.oneOf(CLIENT_TO_NODE_ENCRYPTION_MODES),
    clusterName: PropTypes.string,
    onSubmit: PropTypes.func,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(ClusterCtnEncryptionModeModal);
