import { lazy } from 'react';

import logger from 'utils/logger';

async function importOrReload(dynamicImportFn) {
  const regexToExtractPath = /import\("(.*?)"\)/;
  const importedPath = String(dynamicImportFn).match(regexToExtractPath)?.[1];
  const importedModuleCommitHead = importedPath?.split('.')?.[2];
  const currentCommitHead = process.env.COMMIT_HEAD;

  let tries = 0;
  while (tries < 3) {
    try {
      const importedModule = await dynamicImportFn();
      return importedModule;
    } catch (err) {
      tries++;
      logger.warn({
        prefix: 'build/lazyImport',
        message: `Failed to fetch dynamically imported ${importedPath}. Commit heads(current/requested): ${currentCommitHead} / ${importedModuleCommitHead}. Trying again.`,
        error: err,
      });
      if (tries >= 3) {
        logger.error({
          prefix: 'build/lazyImport',
          message: `Failed to fetch dynamically imported ${importedPath}. Commit heads(current/requested): ${currentCommitHead} / ${importedModuleCommitHead}. Refreshing the page.`,
          error: err,
        });
        window.location.href = '/';
        return { default: () => <></> };
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }
}

function reactLazyPreload(importStatement) {
  const Component = lazy(() => importOrReload(importStatement));
  Component.preload = importStatement;
  return Component;
}

const AppRoutes = {
  ClusterDetails: reactLazyPreload(() => import('./cluster-details')), // Preload in cluster list (after login)
  ClusterDetailsHeader: reactLazyPreload(
    () => import('./cluster-details/ClusterDetailsHeader') // Preload in cluster list (after login)
  ),
  ClustersList: reactLazyPreload(() => import('./clusters-list')), // Preload in login
  NewCluster: reactLazyPreload(() => import('./new-cluster')), // Preload in cluster list (after login) and nav menu mouse enter
  NewClusterHeader: reactLazyPreload(
    () => import('./new-cluster/NewClusterHeader') // Preload in cluster list (after login) and nav menu mouse enter
  ),
  NewClusterDataProvider: reactLazyPreload(
    () => import('components/pages/new-cluster/hooks/context') // Preload in cluster list (after login) and nav menu mouse enter
  ),
  VPCPeering: reactLazyPreload(() => import('./vpc-peering')), // Preload in new cluster and cluster details
  VPCPeeringHeader: reactLazyPreload(() => import('./vpc-peering/header')), // Preload in new cluster and cluster details
  CloudAccountSetup: reactLazyPreload(() => import('./cloud-account')), // Preload on cloud account modal and account
  CloudAccountSetupHeader: reactLazyPreload(
    () => import('./cloud-account/header') // Preload on cloud account modal and account
  ),
  Billing: reactLazyPreload(() => import('./billing')), // Preload on top-right user section mouse enter
  Maintenance: reactLazyPreload(() => import('./maintenance-window')),
  NotificationPreferences: reactLazyPreload(
    () => import('./notification-preferences')
  ),
  Connections: reactLazyPreload(() => import('./cluster-details/connections')),
  Usage: reactLazyPreload(() => import('./usage')),
  // Managed Resources
  ManagedResources: reactLazyPreload(() => import('./managed-resources')), // Preload on top-right user section mouse enter
  ManagedResourcesHeader: reactLazyPreload(
    () => import('./managed-resources/header')
  ),
  Byoa: reactLazyPreload(() => import('./managed-resources/byoa')), // Preload on managed resources page
  ByoaEdit: reactLazyPreload(
    () => import('./managed-resources/byoa/EditCloudAccount')
  ), // Preload on managed resources page
  Byok: reactLazyPreload(() => import('./managed-resources/byok')), // Preload on managed resources page
};

export default AppRoutes;
